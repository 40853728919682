import React, { useEffect, useState } from "react";
import {
  Button,
  Dropdown,
  Grid,
  GridRow,
  Header,
  Icon,
  Input,
  Pagination,
  Segment,
  Table,
} from "semantic-ui-react";
import * as usersService from "../../services/usersService";
import AgentGeneralUserModal from "../modalForms/AgentGeneralUserModal";

function UsersListTable({ intermediaireUuid }) {
  const [openUserModal, setOpenUserModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [usersPagination, setUsersPagination] = useState({});
  const [selectedUserUuid, setSelectedUserUuid] = useState(null);

  const fetchUsers = async () => {
    try {
      const response = await usersService.fetchAllByAgentGeneral(
        intermediaireUuid
      );
      if (response?.status === 200) {
        setUsers(response?.data?.users);
        setUsersPagination({
          currentPage: response?.data?.currentPage,
          totalPages: response?.data?.totalPages,
          totalCount: response?.data?.totalCount,
          prevPage: response?.data?.prevPage,
          nextPage: response?.data?.nextPage,
        });
      }
    } catch (error) {}
  };

  const handleUsersPaginationChange = (event, data) => {
    console.log(data);
  };

  useEffect(() => {
    if (intermediaireUuid) {
      fetchUsers();
    }
  }, [intermediaireUuid]);

  return (
    <>
      <GridRow as={Segment}>
        <Grid stackable>
          <Grid.Column
            width={8}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Header>Liste des utilisateurs</Header>
          </Grid.Column>
          <Grid.Column
            width={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Input placeholder="Rechercher..." className="search">
              <input name="search" />
            </Input>

            <Button
              style={{ marginLeft: "10px" }}
              circular
              icon={<Icon name="add" />}
              onClick={() => {
                setOpenUserModal(true);
              }}
            />
          </Grid.Column>
        </Grid>

        <Table striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>#</Table.HeaderCell>
              <Table.HeaderCell>Nom</Table.HeaderCell>
              <Table.HeaderCell>Prénom</Table.HeaderCell>
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.HeaderCell>Téléphones</Table.HeaderCell>
              <Table.HeaderCell>Admin</Table.HeaderCell>
              <Table.HeaderCell>Actif</Table.HeaderCell>
              <Table.HeaderCell textAlign="right">Actions </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {users?.map((user, index) => {
              return (
                <Table.Row key={`${user.idusers_compagnies}`}>
                  <Table.Cell>{index + 1}</Table.Cell>
                  <Table.Cell>{user?.last_name}</Table.Cell>
                  <Table.Cell>{user?.first_name}</Table.Cell>
                  <Table.Cell>{user?.email}</Table.Cell>
                  <Table.Cell>{user?.phone}</Table.Cell>
                  <Table.Cell>{`${
                    user.is_admin === 1 ? "OUI" : "NON"
                  }`}</Table.Cell>
                  <Table.Cell>{`${
                    user.active === 1 ? "OUI " : "NON"
                  }`}</Table.Cell>
                  <Table.Cell textAlign="right">
                    <Dropdown
                      pointing="right"
                      icon={<Icon name="ellipsis vertical" />}
                    >
                      <Dropdown.Menu>
                        <Dropdown.Item
                          icon={<Icon name="eye" />}
                          content="Détails"
                        />
                        <Dropdown.Item
                          icon={<Icon name="edit" />}
                          content="Editer"
                        />
                        <Dropdown.Item
                          icon={<Icon name="trash" />}
                          content="Supprimer"
                        />
                      </Dropdown.Menu>
                    </Dropdown>
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
          {usersPagination?.totalPages > 1 && (
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan="8" textAlign="right">
                  <Pagination
                    activePage={usersPagination?.currentPage}
                    totalPages={usersPagination?.totalPages}
                    onPageChange={handleUsersPaginationChange}
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          )}
        </Table>
      </GridRow>
      <AgentGeneralUserModal
        agentGeneralUuid={intermediaireUuid}
        open={openUserModal}
        handleClose={() => {
          setSelectedUserUuid(null);
          setOpenUserModal(false);
        }}
        handleCallback={() => {
          fetchUsers();
        }}
        setOpen={setOpenUserModal}
        selectedUserUuid={selectedUserUuid}
      />
    </>
  );
}

export default UsersListTable;
