import React from "react";
import { Link } from "react-router-dom";
import { Accordion, Icon, Menu } from "semantic-ui-react";

function CompanyMenus({
  activeIndex,
  activeSubMenu,
  handleItemClick,
  handleSubMenuClick,
}) {
  return (
    <Accordion
      as={Menu}
      fluid
      vertical
      style={{ borderRadius: "0px", border: "none" }}
    >
      <Menu.Item
        as={Link}
        to="company/dashboard"
        active={activeIndex === "dashboard"}
        index={"dashboard"}
        onClick={handleItemClick}
      >
        <span>
          <Icon name="dashboard" /> Dashboard
        </span>
      </Menu.Item>
      <Menu.Item>
        <Accordion.Title
          active={activeIndex === "production"}
          index={"production"}
          onClick={handleItemClick}
        >
          <Icon name="bus" /> Production
          <i className="right dropdown icon"></i>
        </Accordion.Title>
        <Accordion.Content active={activeIndex === "production"}>
          <Menu.Menu>
            <Menu.Item
              as={Link}
              index={"company/production/souscripteurs"}
              active={activeSubMenu === "company/production/souscripteurs"}
              onClick={handleSubMenuClick}
              to="company/production/souscripteurs"
            >
              Liste des souscripteurs
            </Menu.Item>
            <Menu.Item
              as={Link}
              to="company/production/polices"
              index={"company/production/polices"}
              active={activeSubMenu === "company/production/polices"}
              onClick={handleSubMenuClick}
            >
              Liste des polices
            </Menu.Item>
            <Menu.Item
              as={Link}
              to="company/souscriptions/mono/add"
              index={"company/souscriptions/mono/add"}
              active={activeSubMenu === "company/souscriptions/mono/add"}
              onClick={handleSubMenuClick}
            >
              Production Mono
            </Menu.Item>
            <Menu.Item
              as={Link}
              to="company/souscriptions/flotte/add"
              index={"company/souscriptions/flotte/add"}
              active={activeSubMenu === "company/souscriptions/flotte/add"}
              onClick={handleSubMenuClick}
            >
              Production Flotte
            </Menu.Item>
            <Menu.Item
              as={Link}
              to="company/avenants"
              index={"company/avenants"}
              active={activeSubMenu === "company/avenants"}
              onClick={handleSubMenuClick}
            >
              Gestion des avenants
            </Menu.Item>
          </Menu.Menu>
        </Accordion.Content>
      </Menu.Item>

      <Menu.Item>
        <Accordion.Title
          active={activeIndex === "etats"}
          index={"etats"}
          onClick={handleItemClick}
        >
          <Icon name="file alternate" /> Etats
          <i className="right dropdown icon"></i>
        </Accordion.Title>
        <Accordion.Content active={activeIndex === "etats"}>
          <Menu.Menu>
            <Menu.Item
              as={Link}
              to="company/etats/bordereau-de-production"
              index={"company/etats/bordereau-de-production"}
              active={activeSubMenu === "company/etats/bordereau-de-production"}
              onClick={handleSubMenuClick}
            >
              Bordereaux de production
            </Menu.Item>
            <Menu.Item as="a">Bordereaux des sinistres réglés</Menu.Item>
            <Menu.Item as="a">Bordereaux des avis de sinistre</Menu.Item>
            <Menu.Item as="a">Bordereaux Avis d'échéance</Menu.Item>
          </Menu.Menu>
        </Accordion.Content>
      </Menu.Item>

      <Menu.Item>
        <Accordion.Title
          index="stocks"
          active={activeIndex === "stocks"}
          onClick={handleItemClick}
        >
          <Icon name="box" /> Stocks
          <i className="right dropdown icon"></i>
        </Accordion.Title>
        <Accordion.Content active={activeIndex === "stocks"}>
          <Menu.Menu>
            <Menu.Item
              as={Link}
              to="company/stocks/commandes"
              index={"company/stocks/commandes"}
              active={activeSubMenu === "company/stocks/commandes"}
              onClick={handleSubMenuClick}
            >
              Commandes
            </Menu.Item>
            <Menu.Item
              as={Link}
              to="company/stocks/demandes"
              index={"company/stocks/demandes"}
              active={activeSubMenu === "company/stocks/demandes"}
              onClick={handleSubMenuClick}
            >
              Demandes d'attestions
            </Menu.Item>
            <Menu.Item
              as={Link}
              to="company/stocks/magasins"
              index={"company/stocks/magasins"}
              active={activeSubMenu === "company/stocks/magasins"}
              onClick={handleSubMenuClick}
            >
              Magasins
            </Menu.Item>
          </Menu.Menu>
        </Accordion.Content>
      </Menu.Item>

      <Menu.Item>
        <Accordion.Title
          index="stocks/reseaux"
          active={activeIndex === "stocks/reseaux"}
          onClick={handleItemClick}
        >
          <Icon name="box" /> Stocks Reseaux
          <i className="right dropdown icon"></i>
        </Accordion.Title>
        <Accordion.Content active={activeIndex === "stocks/reseaux"}>
          <Menu.Menu>
            <Menu.Item
              as={Link}
              to="company/stocks/distributions-requests"
              index={"company/stocks/distributions-requests"}
              active={activeSubMenu === "company/stocks/distributions-requests"}
              onClick={handleSubMenuClick}
            >
              Demandes d'attestions
            </Menu.Item>
          </Menu.Menu>
        </Accordion.Content>
      </Menu.Item>

      <Menu.Item>
        <Accordion.Title
          index="gestion"
          onClick={handleItemClick}
          active={activeIndex === "gestion"}
        >
          <Icon name="car crash" /> Gestion des sinistres
          <i className="right dropdown icon"></i>
        </Accordion.Title>
        <Accordion.Content active={activeIndex === "gestion"}>
          <Menu.Menu>
            <Menu.Item as={Link} to="company/sinistres/create">
              Déclaration d'un sinistre
            </Menu.Item>
            <Menu.Item as="a">Estimation</Menu.Item>
            <Menu.Item as="a">Règlement</Menu.Item>
            <Menu.Item as={Link} to="company/sinistres">
              Liste des sinistres
            </Menu.Item>
          </Menu.Menu>
        </Accordion.Content>
      </Menu.Item>

      <Menu.Item>
        <Accordion.Title
          index="cession"
          active={activeIndex === "cession"}
          onClick={handleItemClick}
        >
          <Icon name="expand alternate" /> Cessions & Rétrocessions
          <i className="right dropdown icon"></i>
        </Accordion.Title>
        <Accordion.Content active={activeIndex === "cession"}>
          <Menu.Menu>
            <Menu.Item as="a">Cessions</Menu.Item>
            <Menu.Item as="a">Rétrocessions</Menu.Item>
          </Menu.Menu>
        </Accordion.Content>
      </Menu.Item>

      <Menu.Item
        as={Link}
        to="company/details"
        index={"company"}
        onClick={handleItemClick}
        active={activeIndex === "company"}
      >
        <span>
          <Icon name="building" /> Compagnie
        </span>
      </Menu.Item>
      <Menu.Item
        as={Link}
        to="company/partenaires"
        index={"partenaires"}
        onClick={handleItemClick}
        active={activeIndex === "partenaires"}
      >
        <span>
          <Icon name="share alternate" /> Mes partenaires
        </span>
      </Menu.Item>
      <Menu.Item>
        <Accordion.Title
          index="parametres-tarification"
          onClick={handleItemClick}
          active={activeIndex === "parametres-tarification"}
        >
          <Icon name="cogs" /> Paramètres de tarification
          <i className="right dropdown icon"></i>
        </Accordion.Title>
        <Accordion.Content active={activeIndex === "parametres-tarification"}>
          <Menu.Menu>
            <Menu.Item
              as={Link}
              to="company/production/parametres-tarification/directives"
              index={"company/production/parametres-tarification/directives"}
              active={
                activeSubMenu ===
                "company/production/parametres-tarification/directives"
              }
              onClick={handleSubMenuClick}
            >
              Directives de tarification
            </Menu.Item>
            <Menu.Item
              as={Link}
              to="company/production/parametres-tarification/garanties-complementaires"
              index={
                "company/production/parametres-tarification/garanties-complementaires"
              }
              active={
                activeSubMenu ===
                "company/production/parametres-tarification/garanties-complementaires"
              }
              onClick={handleSubMenuClick}
            >
              Garanties complémentaires
            </Menu.Item>
          </Menu.Menu>
        </Accordion.Content>
      </Menu.Item>
    </Accordion>
  );
}

export default CompanyMenus;
