import { api } from "../../axios/api";

export const findAll = async (params = {}) => {
  return api.get(`/gestion-production/polices`, {
    params,
  });
};

export const findOne = async (id) => {
  return api.get(`/gestion-production/polices/${id}`);
};
export const create = async (data) => {
  return api.post(`/gestion-production/polices`, data);
};

export const makePayment = async (data) => {
  return api.post(`/gestion-production/polices/payments`, data);
};
export const changementImmaticulation = async (data) => {
  return api.put(`/gestion-production/polices/actes/changement-immatriculation`, data);
};
export const changementDeNom = async (data) => {
  return api.put(`/gestion-production/polices/actes/changement-nom`, data);
};

export const suspensionPolice = async (id) => {
  return api.put(`/gestion-production/polices/actes/suspension/${id}`);
};

export const deleteSouscription = async (uuid) => {
  return api.delete(`/gestion-production/souscriptions/${uuid}`);
};
