import {
  PDFViewer,
  Page as PageView,
  Text,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import dayjs from "dayjs";
import React from "react";

function DuplicaPreview({ souscription, avenant_uuid }) {
  const avenant = souscription.avenants.find(
    (avenant) => avenant.uuid === avenant_uuid
  );

  return (
    <PDFViewer style={{ width: "100%", height: "100vh" }}>
      <Document>
        <PageView size="A4" style={styles.page}>
          <View style={styles.avenantHeaderSection}>
            <Text style={styles.avenantHeaderTitle}>
              Avenant de duplicata d'attestation
            </Text>
          </View>

          <View
            style={{
              textAlign: "center",
              fontSize: 14,
              fontWeight: "bold",
              marginTop: 10,
            }}
          >
            <Text> Conditions particulières</Text>
          </View>
          <View
            style={{
              marginTop: 5,
              padding: 5,
              paddingTop: 0,
              border: "1px solid #dce1e6",
            }}
          >
            <View style={{ display: "flex", flexDirection: "column" }}>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <View
                  style={{
                    width: "30%",
                    textAlign: "center",
                    backgroundColor: "#dce1e6",
                    padding: 2,
                  }}
                >
                  <Text>Avenant</Text>
                </View>
              </View>
              <View style={{ marginTop: 5 }}>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Text>Numéro de police</Text>
                  <Text>{souscription?.souscription_polices?.numero_police}</Text>
                </View>
              </View>
              <View style={{ marginTop: 5 }}>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <View>
                    <Text>Date avenant</Text>
                  </View>
                  <View>
                    <Text>
                      {dayjs(avenant?.date_avenant).format("DD/MM/YYYY")}
                    </Text>
                  </View>
                  <View>
                    <Text>Date d'effet</Text>
                  </View>
                  <View>
                    <Text>
                      {dayjs(avenant?.date_avenant)
                        .hour(0)
                        .minute(0)
                        .second(0)
                        .add(1, "day")
                        .format("DD/MM/YYYY")}
                    </Text>
                  </View>
                  <View>
                    <Text>Date d'expiration</Text>
                  </View>
                  <View>
                    <Text>
                      {dayjs(souscription?.conditions_particulieres?.date_effet)
                        .add(
                          Number(
                            souscription?.conditions_particulieres
                              ?.pool_directives_tarifaires_has_insurances_durations
                              ?.insurances_durations?.months_number
                          ),
                          "months"
                        )
                        .add(-1, "day")
                        .format("DD/MM/YYYY")}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View
            style={{
              marginTop: 4,
              padding: 5,
              paddingTop: 0,
              border: "1px solid #dce1e6",
            }}
          >
            <View style={{ display: "flex", flexDirection: "column" }}>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <View
                  style={{
                    width: "30%",
                    textAlign: "center",
                    backgroundColor: "#dce1e6",
                    padding: 2,
                  }}
                >
                  <Text>Police</Text>
                </View>
              </View>
              <View style={{ marginTop: 5 }}>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <View>
                    <Text>Bureau Direct</Text>
                  </View>
                  <View>
                    <Text>
                      {souscription?.souscription_origine?.compagnies_agencies
                        ?.denomination ||
                        souscription?.souscription_origine?.intermediaires
                          ?.denomination}
                    </Text>
                  </View>
                  <View>
                    <Text>Status</Text>
                  </View>
                  <View>
                    <Text>Autres</Text>
                  </View>
                </View>
              </View>
              <View style={{ marginTop: 5 }}>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <View>
                    <Text>Adresse</Text>
                  </View>
                  <View>
                    <Text>
                      {souscription?.souscription_origine?.compagnies_agencies
                        ?.address ||
                        souscription?.souscription_origine?.intermediaires
                          ?.address}
                    </Text>
                  </View>
                </View>
              </View>
              <View style={{ marginTop: 5 }}>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <View>
                    <Text>Téléphone</Text>
                  </View>
                  <View>
                    <Text>
                      {souscription?.souscription_origine?.compagnies_agencies
                        ?.phone_1 ||
                        souscription?.souscription_origine?.intermediaires
                          ?.phone_1}
                    </Text>
                  </View>
                  <View>
                    <Text>Fax</Text>
                  </View>
                  <View>
                    <Text>
                      {souscription?.souscription_origine?.compagnies_agencies
                        ?.fax ||
                        souscription?.souscription_origine?.intermediaires?.fax}
                    </Text>
                  </View>
                </View>
              </View>
              <View style={{ marginTop: 5 }}>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <View>
                    <Text>Produit</Text>
                  </View>
                  <View>
                    <Text>Transport Public de Voyageurs</Text>
                  </View>
                  <View>
                    <Text>Durée</Text>
                  </View>
                  <View>
                    <Text>
                      {dayjs(souscription?.conditions_particulieres?.date_effet)
                        .add(
                          Number(
                            souscription?.conditions_particulieres
                              ?.pool_directives_tarifaires_has_insurances_durations
                              ?.insurances_durations?.months_number
                          ),
                          "months"
                        )
                        .diff(
                          dayjs(
                            souscription?.conditions_particulieres?.date_effet
                          ),
                          "day"
                        )}{" "}
                      jours
                    </Text>
                  </View>
                </View>
              </View>
              <View style={{ marginTop: 5 }}>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <View>
                    <Text>Date d'éffet</Text>
                  </View>
                  <View>
                    <Text>
                      {dayjs(
                        souscription?.conditions_particulieres?.date_effet
                      ).format("DD/MM/YYYY")}
                    </Text>
                  </View>
                  <View>
                    <Text>Date d'échéance</Text>
                  </View>
                  <View>
                    <Text>
                      {dayjs(souscription?.conditions_particulieres?.date_effet)
                        .add(
                          Number(
                            souscription?.conditions_particulieres
                              ?.pool_directives_tarifaires_has_insurances_durations
                              ?.insurances_durations?.months_number
                          ),
                          "months"
                        )
                        .add(-1, "day")
                        .format("DD/MM/YYYY")}
                    </Text>
                  </View>
                  <View>
                    <Text>Date d'expiration</Text>
                  </View>
                  <View>
                    <Text>
                      {dayjs(souscription?.conditions_particulieres?.date_effet)
                        .add(
                          Number(
                            souscription?.conditions_particulieres
                              ?.pool_directives_tarifaires_has_insurances_durations
                              ?.insurances_durations?.months_number
                          ),
                          "months"
                        )
                        .add(-1, "day")
                        .format("DD/MM/YYYY")}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View
            style={{
              marginTop: 4,
              padding: 5,
              paddingTop: 0,
              border: "1px solid #dce1e6",
            }}
          >
            <View style={{ display: "flex", flexDirection: "column" }}>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <View
                  style={{
                    width: "30%",
                    textAlign: "center",
                    backgroundColor: "#dce1e6",
                    padding: 2,
                  }}
                >
                  <Text>Assuré</Text>
                </View>
              </View>
              <View style={{ marginTop: 5 }}>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <View>
                    <Text>Nom et prénom</Text>
                  </View>
                  <View>
                    <Text>
                      {souscription?.souscripteur_assure?.assures?.nom_assure}
                    </Text>
                  </View>
                  <View>
                    <Text>Téléphone</Text>
                  </View>
                  <View>
                    <Text>
                      {souscription?.souscripteur_assure?.assures?.phone_assure}
                    </Text>
                  </View>
                </View>
              </View>
              <View style={{ marginTop: 5 }}>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <View>
                    <Text>Adresse</Text>
                  </View>
                  <View>
                    <Text>
                      {
                        souscription?.souscripteur_assure?.assures
                          ?.address_assure
                      }
                    </Text>
                  </View>
                </View>
              </View>
              <View style={{ marginTop: 5 }}>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <View>
                    <Text>Activité</Text>
                  </View>
                  <View>
                    <Text></Text>
                  </View>
                  <View>
                    <Text>Profession</Text>
                  </View>
                  <View>
                    <Text>
                      {
                        souscription?.souscripteur_assure?.assures?.jobs
                          ?.libelle
                      }
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View
            style={{
              marginTop: 4,
              padding: 5,
              paddingTop: 0,
              border: "1px solid #dce1e6",
            }}
          >
            <View style={{ display: "flex", flexDirection: "column" }}>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <View
                  style={{
                    width: "30%",
                    textAlign: "center",
                    backgroundColor: "#dce1e6",
                    padding: 2,
                  }}
                >
                  <Text>Caractéristiques du véhicule</Text>
                </View>
              </View>
              <View style={{ marginTop: 5 }}>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <View>
                    <Text>Tarif</Text>
                  </View>
                  <View>
                    <Text>Transport Public de Voyageurs</Text>
                  </View>
                  <View>
                    <Text>Zone de circulation</Text>
                  </View>
                  <View>
                    <Text>
                      {
                        souscription?.souscription_identite_vehicule
                          ?.zones_circulation?.libelle
                      }
                    </Text>
                  </View>
                </View>
              </View>
              <View style={{ marginTop: 5 }}>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <View>
                    <Text>Marque</Text>
                  </View>
                  <View>
                    <Text>
                      {
                        souscription?.souscription_identite_vehicule
                          ?.marque_vehicule
                      }
                    </Text>
                  </View>
                  <View>
                    <Text>Genre</Text>
                  </View>
                  <View>
                    <Text>
                      {
                        souscription?.souscription_identite_vehicule
                          ?.genre_vehicule
                      }
                    </Text>
                  </View>
                  <View>
                    <Text>Carossérie</Text>
                  </View>
                  <View>
                    <Text>
                      {
                        souscription?.souscription_identite_vehicule
                          ?.carrosserie
                      }
                    </Text>
                  </View>
                </View>
              </View>
              <View style={{ marginTop: 5 }}>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <View>
                    <Text>Immatriculation</Text>
                  </View>
                  <View>
                    <Text>
                      {
                        souscription?.souscription_identite_vehicule
                          ?.immatriculation_vehicule
                      }
                    </Text>
                  </View>
                  <View>
                    <Text>Chassis</Text>
                  </View>
                  <View>
                    <Text>
                      {
                        souscription?.souscription_identite_vehicule
                          ?.numero_chassis
                      }
                    </Text>
                  </View>
                  <View>
                    <Text>Mise en circulation</Text>
                  </View>
                  <View>
                    <Text>
                      {dayjs(
                        souscription?.souscription_identite_vehicule
                          ?.date_premiere_mise_en_circulation
                      ).format("DD/MM/YYYY")}
                    </Text>
                  </View>
                </View>
              </View>
              <View style={{ marginTop: 5 }}>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <View>
                    <Text>Puissance fiscale</Text>
                  </View>
                  <View>
                    <Text>
                      {
                        souscription?.souscription_identite_vehicule
                          ?.puissance_fiscale
                      }
                    </Text>
                  </View>
                  <View>
                    <Text>Nombre de places</Text>
                  </View>
                  <View>
                    <Text>
                      {
                        souscription?.souscription_identite_vehicule
                          ?.nombre_places
                      }
                    </Text>
                  </View>
                  <View>
                    <Text>Source d'énergie</Text>
                  </View>
                  <View>
                    <Text>
                      {
                        souscription?.souscription_identite_vehicule
                          ?.sources_energie?.libelle
                      }
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={{ marginTop: 10 }}>
            <Text>
              Il n'est rien changé aux autres clauses et conditions de la police
              à laquelle le présent avenant demeure annexé. Sont nulle toute
              adjonction ou modifications matérielle non revêtues du visa de la
              compagnie.
            </Text>
          </View>
          <View
            style={{ display: "flex", flexDirection: "column", marginTop: 10 }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <View
                style={{
                  width: "30%",
                  textAlign: "center",
                  backgroundColor: "#dce1e6",
                  padding: 2,
                }}
              >
                <Text>Décompte de prime</Text>
              </View>
            </View>
            <View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  marginTop: 5,
                  backgroundColor: "#dce1e6",
                }}
              >
                <View style={{ width: "20%", borderColor: "#000", padding: 2 }}>
                  <Text>Prime nette</Text>
                </View>
                <View style={{ width: "20%", borderColor: "#000", padding: 2 }}>
                  <Text>Accessoires</Text>
                </View>
                <View style={{ width: "20%", borderColor: "#000", padding: 2 }}>
                  <Text>Taxes</Text>
                </View>
                <View style={{ width: "20%", borderColor: "#000", padding: 2 }}>
                  <Text>Carte rose</Text>
                </View>
                <View style={{ width: "20%", borderColor: "#000", padding: 2 }}>
                  <Text>Total TTC</Text>
                </View>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <View style={{ width: "20%", borderColor: "#000", padding: 5 }}>
                  <Text>0</Text>
                </View>
                <View style={{ width: "20%", borderColor: "#000", padding: 5 }}>
                  <Text>5 000</Text>
                </View>
                <View style={{ width: "20%", borderColor: "#000", padding: 5 }}>
                  <Text>750 </Text>
                </View>
                <View style={{ width: "20%", borderColor: "#000", padding: 5 }}>
                  <Text>{souscription?.carte_rose}</Text>
                </View>
                <View style={{ width: "20%", borderColor: "#000", padding: 5 }}>
                  <Text>5 750</Text>
                </View>
              </View>
            </View>
          </View>

          <View style={{ marginTop: 25 }}>
            <Text style={{ textAlign: "right" }}>
              Fait en 02 exemplaires à ___________________________, le{" "}
              {dayjs().format("DD/MM/YYYY")}
            </Text>
            <View
              style={{
                marginTop: 25,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                fontWeight: "extrabold",
              }}
            >
              <Text>Pour l'assuré</Text>
              <Text>Pour la compagnie</Text>
            </View>
          </View>
        </PageView>
      </Document>
    </PDFViewer>
  );
}

export default DuplicaPreview;

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFF",
    padding: 50,
    fontSize: 10,
    lineHeight: 1.5,
  },
  avenantHeaderSection: {
    backgroundColor: "#dce1e6",
    padding: 10,
  },
  avenantHeaderTitle: {
    fontSize: 14,
    fontWeight: "bold",
    textAlign: "center",
    textTransform: "uppercase",
  },
  souscripteurSection: {
    marginTop: 50,
    marginBottom: 50,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    textTransform: "uppercase",
    gap: 10,
    padding: 10,
  },
  souscripteurItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    textTransform: "uppercase",
    width: "50%",
  },
  souscripteurItemValue: {
    fontWeight: "bold",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    textTransform: "uppercase",
    fontSize: 14,
  },
});
