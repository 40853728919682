import { useEffect } from "react";
import { getByActiveDirectivesTarifaires } from "../../services/gestion_production/tableauGarantiesRcService";

const useCalculateSouscription = (
  values,
  setFieldValue,
  insuranceDurations,
  directivesTarifairesValues
) => {
  const calculateData = async () => {
    try {
      let primeNetteAuto = 0;
      let primeNetteIAC = 0;
      let primeNetteTotale = 0;
      let fraisGestionPool = 0;
      let coutPolice = 0;
      let taxeContratAssurance = 0;
      let carteRose = 0;
      let droitTimbreAuto = 0;
      let totaleToutesGaranties = 0;

      // Calcul de la prime nette automobile (A)
      if (
        isNaN(Number(values.identite_vehicule.nombre_places)) ||
        isNaN(values.identite_vehicule.puissance_fiscale) ||
        isNaN(values.identite_vehicule.idsources_energie)
      ) {
        return;
      }

      const response = await getByActiveDirectivesTarifaires({
        cv: Number(values.identite_vehicule.puissance_fiscale),
        places: Number(values.identite_vehicule.nombre_places),
        id_source_energie: values.identite_vehicule.idsources_energie,
      });

      if (response?.status !== 200 || !response?.data) {
        return;
      }

      const primeNetteAutoAnnuelle = Number(response.data.value);

      let insuranceDuration = insuranceDurations.find(
        (duration) =>
          duration.uuid === values.conditions_particulieres.type_renouvellement
      );

      if (!insuranceDuration) {
        setFieldValue("type_renouvellement", "", false);
        return;
      }

      primeNetteAuto = Math.round(
        (primeNetteAutoAnnuelle * insuranceDuration?.percentage) / 100
      );

      // Calcul de la prime nette IAC  (B)
      primeNetteIAC = Number(values.prime_nette_iac);

      // Calcul des total des garanties  (C)
      const totalGaranties = values.garanties.reduce((acc, garantie) => {
        garantie.risques.forEach((risque) => {
          acc += Number(risque.value);
        });
        return acc;
      }, 0);

      const totalGarantiesComplementaires =
        values.garanties_complementaires.reduce((acc, garantie) => {
          return acc + Number(garantie.value);
        }, 0);

      totaleToutesGaranties =
        Number(totalGaranties) + Number(totalGarantiesComplementaires);

      // Calcule de la prime nette totale (D)=> (A+B+C)
      primeNetteTotale =
        Number(primeNetteAuto) +
        Number(primeNetteIAC) +
        Number(totaleToutesGaranties);

      // Calcule des frais de gestion du pool (E)=> (A + B) * frais_gestion_pool
      fraisGestionPool = Math.round(
        (directivesTarifairesValues.find(
          (item) => item.name === "frais_gestion_pool"
        ).value /
          100) *
          (primeNetteAuto + primeNetteIAC)
      );

      //Cout de la police (F)
      coutPolice = Number(values.cout_police);

      // Calcul des taxes (G) => (D + E + F) * taux_taxe_contrat_assurance
      taxeContratAssurance = Math.round(
        (directivesTarifairesValues.find(
          (item) => item.name === "taxe_contrat_assurance"
        ).value /
          100) *
          (primeNetteTotale + fraisGestionPool + coutPolice)
      );

      // Carte rose (H)
      carteRose = Number(values.carte_rose);
      // Droit de timbre (I)
      droitTimbreAuto = Number(values.droit_de_timbre_automobile);

      // Total prime nette (J) => (D + E + F + G + H + I)
      const totalPrimeNette =
        primeNetteTotale +
        fraisGestionPool +
        coutPolice +
        taxeContratAssurance +
        carteRose +
        droitTimbreAuto;

      // set values

      setFieldValue(
        "prime_nette",
        isNaN(parseFloat(primeNetteAuto)) ? 0 : Math.ceil(primeNetteAuto),
        true
      );

      setFieldValue(
        "taxes",
        isNaN(parseFloat(taxeContratAssurance))
          ? 0
          : Math.round(taxeContratAssurance),
        true
      );

      setFieldValue(
        "montant_gestion_pool",
        isNaN(parseFloat(fraisGestionPool)) ? 0 : Math.round(fraisGestionPool),
        true
      );
      setFieldValue(
        "prime_nette_totale",
        isNaN(parseFloat(primeNetteTotale)) ? 0 : Math.round(primeNetteTotale),
        true
      );

      setFieldValue("prime_ttc", totalPrimeNette);
    } catch (error) {
      console.log(error?.message);
    }
  };

  useEffect(() => {
    calculateData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values.identite_vehicule?.nombre_places,
    values.identite_vehicule?.puissance_fiscale,
    values.cout_police,
    values.identite_vehicule?.idsources_energie,
    values.conditions_particulieres?.type_renouvellement,
    values.garanties,
    values.garanties_complementaires,
    values.identite_vehicule?.idsources_energie,
    values.identite_vehicule?.nombre_places,
    values.identite_vehicule?.puissance_fiscale,
    values.identite_vehicule?.date_mise_en_circulation,
    values.identite_vehicule.valeur_a_neuf,
    values.identite_vehicule.valeur_venale,
  ]);

  return calculateData;
};

export default useCalculateSouscription;
