import dayjs from "dayjs";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbDivider,
  BreadcrumbSection,
  Divider,
  Dropdown,
  Grid,
  GridRow,
  Header,
  Icon,
  Input,
  Label,
  Pagination,
  Segment,
  Table,
} from "semantic-ui-react";
import CompanyCourtierModal from "../../components/modalForms/CompanyCourtierModal";
import Page from "../../components/Page";

import * as compagniesPartnersService from "../../services/compagniesPartnersService";
import * as intermediairesService from "../../services/intermediairesService";
import { getTypeIntermediaireText } from "../../utils/commons";
import IntermediairesFormModal from "../../components/modalForms/IntermediairesFormModal";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { TYPES_INTERMEDIAIRE } from "../../constants";

function CompanyPartnersList() {
  const [partners, setPartners] = useState([]);
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    totalCount: 0,
    prevPage: null,
    nextPage: null,
  });
  const [openCourtierModal, setOpenCourtierModal] = useState(false);
  const [openAgentGeneralModal, setOpenAgentGeneralModal] = useState(false);
  const [selectedPartnerUuid, setSelectedPartnerUuid] = useState(null);
  const navigate = useNavigate();

  const fetchPartners = async ({ page = 1, search = "" } = {}) => {
    try {
      const response = await compagniesPartnersService.fetchAll({
        page,
        search,
      });
      if (response.status === 200) {
        const { partenaires, ...pagination } = response.data;
        setPartners(partenaires);
        setPagination(pagination);
      }
    } catch (error) {}
  };

  const handleOpenAddAgentGeneralModal = () => {
    setOpenAgentGeneralModal(true);
    setSelectedPartnerUuid(null);
  };

  const handleOpenEditAgentGeneralModal = (partner) => {
    setOpenAgentGeneralModal(true);
    setSelectedPartnerUuid(partner?.intermediaires?.uuid);
  };

  const handleCloseEditAgentGeneralModal = () => {
    setOpenAgentGeneralModal(false);
    setSelectedPartnerUuid(null);
  };

  useEffect(() => {
    fetchPartners({ page: pagination.currentPage, search });
    return () => {};
  }, [pagination.currentPage, search]);

  const handleDeleteAgentGeneral = async (uuid) => {
    try {
      Swal.fire({
        title: "Suppression",
        text: "Voulez-vous vraiment supprimer cet intermédiaire? Cette action est irréversible.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui, supprimer!",
        cancelButtonText: "Annuler",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await intermediairesService.deleteIntermediaire(
              uuid
            );
            if (response.status === 200) {
              toast("Intermédiaire supprimé avec succès!");
              fetchPartners({ page: pagination.currentPage, search });
            }
          } catch (error) {
            toast.error(error?.response?.data?.error || "Supression échouée!");
          }
        }
      });
    } catch (error) {
      toast(error?.response?.data?.error || "Une erreur est survenue!");
    }
  };

  return (
    <Page title="Nos Partenaires">
      <GridRow className="CompanyPartnersList">
        <Grid.Row className="!mb-5">
          <Grid.Column width={16}>
            <Breadcrumb>
              <BreadcrumbSection link>Compagnie</BreadcrumbSection>
              <BreadcrumbDivider icon="right arrow" />
              <BreadcrumbSection link as={Link} to="/company/partenaires">
                Mes partenaires
              </BreadcrumbSection>
            </Breadcrumb>
            <Divider />
          </Grid.Column>
        </Grid.Row>
        <Grid stackable>
          <Grid.Column
            width={8}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Header>Liste des partenaires</Header>
          </Grid.Column>
          <Grid.Column
            width={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Input placeholder="Rechercher..." className="search">
              <input
                name="search"
                onChange={(e) => setSearch(e.target.value)}
              />
            </Input>
            <Dropdown
              className="icon !rounded-full !ml-3"
              icon={<Icon name="ellipsis vertical " />}
              button
              circular
              pointing="right"
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  content="Ajouter un Agent Général"
                  onClick={handleOpenAddAgentGeneralModal}
                />
                <Dropdown.Item
                  content="Associer un courtier"
                  onClick={() => setOpenCourtierModal(true)}
                />
              </Dropdown.Menu>
            </Dropdown>
          </Grid.Column>
        </Grid>
        <>
          <Segment>
            <Table striped celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>#</Table.HeaderCell>
                  <Table.HeaderCell>Dénomination</Table.HeaderCell>
                  <Table.HeaderCell>Code</Table.HeaderCell>
                  <Table.HeaderCell>Type</Table.HeaderCell>
                  <Table.HeaderCell>Email</Table.HeaderCell>
                  <Table.HeaderCell>Téléphone</Table.HeaderCell>
                  <Table.HeaderCell>Active</Table.HeaderCell>
                  <Table.HeaderCell>Ajouté le</Table.HeaderCell>
                  <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {partners.map((partner, index) => {
                  return (
                    <Table.Row key={`${partner.idcompagnies_intermediaires}`}>
                      <Table.Cell>{index + 1}</Table.Cell>
                      <Table.Cell>
                        {partner?.intermediaires?.denomination}
                      </Table.Cell>
                      <Table.Cell>{partner?.intermediaires?.code}</Table.Cell>
                      <Table.Cell>
                        {getTypeIntermediaireText(
                          partner?.intermediaires?.type_intermediaire
                        )}
                      </Table.Cell>
                      <Table.Cell>{partner?.intermediaires?.email}</Table.Cell>
                      <Table.Cell>
                        {partner?.intermediaires?.phone_1}/
                        {partner?.intermediaires?.phone_2}
                      </Table.Cell>
                      <Table.Cell>
                        <Label
                          size="mini"
                          color={
                            partner?.intermediaires?.active === 1
                              ? "green"
                              : "red"
                          }
                        >
                          {partner?.intermediaires?.active === 1
                            ? "OUI"
                            : "NON"}
                        </Label>
                      </Table.Cell>
                      <Table.Cell>
                        {dayjs(partner?.created_at).format("DD-MM-YYYY")}
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        <Dropdown
                          pointing="right"
                          icon={<Icon name="ellipsis vertical" />}
                        >
                          <Dropdown.Menu>
                            <Dropdown.Item
                              icon={<Icon name="eye" />}
                              content="Détails"
                              onClick={() =>
                                navigate(
                                  `details/${partner.intermediaires.uuid}`
                                )
                              }
                            />
                            {partner?.intermediaires?.type_intermediaire ===
                            TYPES_INTERMEDIAIRE.AGENT_GENERAL ? (
                              <Dropdown.Item
                                icon={<Icon name="edit" />}
                                content="Editer"
                                onClick={() =>
                                  handleOpenEditAgentGeneralModal(partner)
                                }
                              />
                            ) : null}
                            {partner?.intermediaires?.type_intermediaire ===
                            TYPES_INTERMEDIAIRE.AGENT_GENERAL ? (
                              <Dropdown.Item
                                icon={<Icon name="trash" />}
                                content="Supprimer"
                                onClick={() =>
                                  handleDeleteAgentGeneral(
                                    partner?.intermediaires?.uuid
                                  )
                                }
                              />
                            ) : (
                              <Dropdown.Item
                                icon={<Icon name="trash" />}
                                content="Retirer le courtier"
                                onClick={() => {}}
                              />
                            )}
                          </Dropdown.Menu>
                        </Dropdown>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
              {pagination.totalPages > 1 && (
                <Table.Footer>
                  <Table.Row>
                    <Table.HeaderCell colSpan="9" textAlign="right">
                      <Pagination
                        activePage={pagination.currentPage}
                        totalPages={pagination.totalPages}
                        onPageChange={(e, { activePage }) =>
                          setPagination({
                            ...pagination,
                            currentPage: activePage,
                          })
                        }
                      />
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              )}
            </Table>
          </Segment>
        </>
      </GridRow>
      <CompanyCourtierModal
        open={openCourtierModal}
        setOpen={setOpenCourtierModal}
        callback={fetchPartners}
      />

      <IntermediairesFormModal
        open={openAgentGeneralModal}
        setOpen={setOpenAgentGeneralModal}
        callback={fetchPartners}
        selectedPartnerUuid={selectedPartnerUuid}
        type="agent_general"
        handleClose={handleCloseEditAgentGeneralModal}
        setSelectedPartnerUuid={setSelectedPartnerUuid}
      />
    </Page>
  );
}

export default CompanyPartnersList;
