import React, { Fragment, useState } from "react";
import {
  Button,
  Dropdown,
  Grid,
  GridRow,
  Header,
  Icon,
  Input,
  Table,
} from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { authState } from "../../atoms/authState";
import dayjs from "dayjs";
import ChangementImmatriculationModal from "../modalForms/ActesDeGestion/ChangementImmatriculationModal";
import ChangementDeNomModal from "../modalForms/ActesDeGestion/ChangementDeNomModal";
import ImpressionModal from "./ImpressionModal";
import GenerationPoliceModal from "./GenerationPoliceModal";
import RetraitsDeGarantiesModal from "../modalForms/ActesDeGestion/RetraitsDeGarantiesModal";

import ExtensionDeGarantiesModal from "../modalForms/ActesDeGestion/ExtensionDeGarantiesModal";
import { deleteAvenant } from "../../services/gestion_production/avenantsService";
import { toast } from "react-toastify";

import Swal from "sweetalert2";
import DuplicataAttestestationModal from "../modalForms/ActesDeGestion/DuplicataAttestestationModal";
import AvenantResialiationModal from "../modalForms/ActesDeGestion/AvenantResialiationModal";
import AvenantSuspensionModal from "../modalForms/ActesDeGestion/AvenantSuspensionModal";

function ActesDeGestionPane({
  souscription = null,
  avenants = [],
  idpolices = null,
  onSuccessPaymentCallBack = () => {},
  onSuccessCallBack = () => {},
  openConditionParticuliereModal = () => {},
}) {
  const [openGenerationPoliceModal, setOpenGenerationPoliceModal] =
    useState(false);
  const [openExtensionDeGarantiesModal, setOpenExtensionDeGarantiesModal] =
    useState(false);
  const [
    openChangementImmatriculationModal,
    setOpenChangementImmatriculationModal,
  ] = useState(false);
  const [
    openDuplicataAttestestationModal,
    setOpenDuplicataAttestestationModal,
  ] = useState(false);
  const [openChangementDeNomModal, setOpenChangementDeNomModal] =
    useState(false);
  const [selectedAvenantUuid, setSelectedAvenantUuid] = useState();
  const [openAvenantResialiationModal, setOpenAvenantResialiationModal] =
    useState(false);
  const [openAvenantSuspensionModal, setOpenAvenantSuspensionModal] =
    useState(false);

  const authValue = useRecoilValue(authState);
  const navigate = useNavigate();

  const [openImpressionModal, setOpenImpressionModal] = useState(false);
  const [openRetraitModal, setOpenRetraitModal] = useState(false);

  const handleDeleteAvenant = async (uuid) => {
    try {
      Swal.fire({
        title: "Suppression de l'avenant",
        text: "Voulez-vous vraiment supprimer cet avenant ? Cette action est irréversible",
        icon: "warning",
        confirmButtonText: "Oui",
        showCancelButton: true,
        cancelButtonText: "Annuler",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await deleteAvenant(uuid);
            toast.success("Avenant supprimé avec succès");
            onSuccessCallBack();
          } catch (error) {
            toast.error("Impossible de supprimer l'avenant");
          }
        }
      });
    } catch (error) {
      toast.error("Impossible de supprimer l'avenant");
    }
  };

  return (
    <Fragment>
      <GridRow>
        <>
          <Grid stackable>
            <Grid.Column
              width={8}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Header>Liste des actes</Header>
            </Grid.Column>
            <Grid.Column
              width={8}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Input placeholder="Rechercher..." className="search">
                <input name="search" />
              </Input>
              <Dropdown
                pointing="right"
                style={{ zIndex: 999 }}
                icon={null}
                trigger={
                  <Button
                    style={{ marginLeft: "10px" }}
                    circular
                    icon={<Icon name="add" />}
                  />
                }
              >
                <Dropdown.Menu>
                  <Dropdown.Item
                    content="Résiliation"
                    onClick={() => setOpenAvenantResialiationModal(true)}
                  />
                  <Dropdown.Item
                    content="Suspension"
                    onClick={() => setOpenAvenantSuspensionModal(true)}
                  />
                  <Dropdown.Item content="Modification de prise d'éffet" />
                  <Dropdown.Item
                    content="Changement d'immatriculation"
                    onClick={() => setOpenChangementImmatriculationModal(true)}
                  />
                  <Dropdown.Item content="Remise en vigueur" />
                  <Dropdown.Item content="Incorporation" />
                  <Dropdown.Item
                    content="Retrait de garanties"
                    onClick={() => setOpenRetraitModal(true)}
                  />
                  <Dropdown.Item
                    content="Duplicata d'attestation"
                    onClick={() => setOpenDuplicataAttestestationModal(true)}
                  />
                  <Dropdown.Item
                    content="Extension des garanties"
                    onClick={() => setOpenExtensionDeGarantiesModal(true)}
                  />
                  <Dropdown.Item
                    content="Avenant de changement de nom"
                    onClick={() => setOpenChangementDeNomModal(true)}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </Grid.Column>
          </Grid>
          <Table striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell>Numéro</Table.HeaderCell>
                <Table.HeaderCell>Type</Table.HeaderCell>
                <Table.HeaderCell>Période</Table.HeaderCell>
                <Table.HeaderCell>Création</Table.HeaderCell>
                <Table.HeaderCell>Paiement</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {avenants?.map((avenant, index) => {
                return (
                  <Table.Row key={`${avenant.idavenants}`}>
                    <Table.Cell>{index + 1}</Table.Cell>
                    <Table.Cell>
                      {(avenants?.length - 1 - index)
                        ?.toString()
                        ?.padStart(6, "0")}
                    </Table.Cell>
                    <Table.Cell>{avenant.type_avenant}</Table.Cell>
                    <Table.Cell>
                      {dayjs(avenant.date_effet).format("DD-MM-YYYY")} -{" "}
                      {dayjs(souscription?.conditions_particulieres?.date_effet)
                        .add(
                          Number(
                            souscription?.conditions_particulieres
                              ?.pool_directives_tarifaires_has_insurances_durations
                              ?.insurances_durations?.months_number
                          ),
                          "months"
                        )
                        .add(-1, "day")
                        .format("DD-MM-YYYY")}
                    </Table.Cell>
                    <Table.Cell>
                      {dayjs(avenant.created_at).format("DD-MM-YYYY")}
                    </Table.Cell>
                    <Table.Cell>
                      {avenant?.bills?.payments ? (
                        <>
                          <Icon name="check" color="green" />
                          <span style={{ marginLeft: "5px" }}>
                            {dayjs(
                              avenant?.facturation?.payments?.date_paiement
                            ).format("DD-MM-YYYY HH:mm")}
                          </span>
                        </>
                      ) : (
                        <Icon name="close" color="red" />
                      )}
                    </Table.Cell>

                    <Table.Cell textAlign="right">
                      <Dropdown
                        pointing="right"
                        icon={<Icon name="ellipsis vertical" />}
                      >
                        <Dropdown.Menu>
                          <Dropdown.Item
                            icon={<Icon name="eye" />}
                            content="Détails"
                            onClick={() =>
                              navigate(`details/${avenant?.idavenants}`)
                            }
                          />
                          {avenant.type_avenant !== "AFFAIRE_NOUVELLE" &&
                            !avenant?.bills?.payments && (
                              <Dropdown.Item
                                icon={<Icon name="trash" />}
                                content="Supprimer"
                                onClick={() =>
                                  handleDeleteAvenant(avenant.uuid)
                                }
                              />
                            )}
                          {!souscription?.souscription_polices?.numero_police &&
                            avenant.type_avenant === "AFFAIRE_NOUVELLE" && (
                              <Dropdown.Item
                                icon={<Icon name="plus" />}
                                content="Générer la police"
                                disabled={!avenant?.bills?.payments}
                                onClick={() => {
                                  setSelectedAvenantUuid(avenant.uuid);
                                  setOpenGenerationPoliceModal(true);
                                }}
                              />
                            )}
                          <Dropdown.Item
                            icon={<Icon name="print" />}
                            content="Imprimer"
                            onClick={() => {
                              if (avenant.type_avenant !== "AFFAIRE_NOUVELLE") {
                                setSelectedAvenantUuid(avenant.uuid);
                                setOpenImpressionModal(true);
                              } else {
                                openConditionParticuliereModal();
                              }
                            }}
                          />
                        </Dropdown.Menu>
                      </Dropdown>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </>
      </GridRow>
      <ExtensionDeGarantiesModal
        open={openExtensionDeGarantiesModal}
        setOpen={setOpenExtensionDeGarantiesModal}
        souscription_uuid={souscription?.uuid}
        onSuccessCallBack={onSuccessCallBack}
      />
      <GenerationPoliceModal
        open={openGenerationPoliceModal}
        setOpen={setOpenGenerationPoliceModal}
        souscription={souscription}
        avenant_uuid={selectedAvenantUuid}
        onSuccessPaymentCallBack={onSuccessPaymentCallBack}
        onCloseCallBack={() => {
          setSelectedAvenantUuid(null);
        }}
      />
      <ChangementImmatriculationModal
        open={openChangementImmatriculationModal}
        setOpen={setOpenChangementImmatriculationModal}
        souscription_uuid={souscription?.uuid}
        onSuccessCallBack={onSuccessCallBack}
      />
      <ChangementDeNomModal
        open={openChangementDeNomModal}
        setOpen={setOpenChangementDeNomModal}
        uuid={souscription?.uuid}
        avenants={avenants}
        onSuccessCallBack={onSuccessCallBack}
      />
      <RetraitsDeGarantiesModal
        open={openRetraitModal}
        setOpen={setOpenRetraitModal}
        souscription_uuid={souscription?.uuid}
        onSuccessCallBack={onSuccessCallBack}
      />
      <ImpressionModal
        open={openImpressionModal}
        setOpen={setOpenImpressionModal}
        souscription={souscription}
        avenant_uuid={selectedAvenantUuid}
        onCloseCallBack={() => {
          setSelectedAvenantUuid(null);
        }}
      />
      <DuplicataAttestestationModal
        open={openDuplicataAttestestationModal}
        setOpen={setOpenDuplicataAttestestationModal}
        souscription_uuid={souscription?.uuid}
        onSuccessCallBack={onSuccessCallBack}
      />
      <AvenantResialiationModal
        open={openAvenantResialiationModal}
        setOpen={setOpenAvenantResialiationModal}
        souscription_uuid={souscription?.uuid}
        onSuccessCallBack={onSuccessCallBack}
      />
      <AvenantSuspensionModal
        open={openAvenantSuspensionModal}
        setOpen={setOpenAvenantSuspensionModal}
        souscription_uuid={souscription?.uuid}
        onSuccessCallBack={onSuccessCallBack}
      />
    </Fragment>
  );
}

export default ActesDeGestionPane;
