import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Divider,
  Form,
  Grid,
  Header,
  Input,
  List,
  Message,
  Modal,
  Segment,
} from "semantic-ui-react";
import { toast } from "react-toastify";
import { useFormik, FormikProvider } from "formik";
import * as yup from "yup";
import * as policesService from "../../../services/gestion_production/policesService";
import * as avenantsService from "../../../services/gestion_production/avenantsService";
import * as avariesFacultativesService from "../../../services/gestion_production/pool/avariesFacultativesService";
import * as companyDirectiveValuesService from "../../../services/gestion_production/company/directivesTarifairesValueService";
import * as directivesTarifaireValuesService from "../../../services/gestion_production/directivesTarifairesValueService";

import GarantieItem from "../../production/souscriptions/components/GarantieItem";
import { useRecoilValue } from "recoil";
import { authState } from "../../../atoms/authState";
import numeral from "numeral";
import dayjs from "dayjs";
import {
  collecteDataForExtensionGaranties,
  isDateBeforeDateEffet,
} from "../../../utils/souscription_helper";

const validationSchema = yup.object().shape({
  montant_commission: yup.number(),
  prime_nette: yup.number(),
  prime_nette_totale: yup.number(),
  prime_nette_iac: yup.number(),
  cout_police: yup.number(),
  taxes: yup.number(),
  montant_gestion_pool: yup.number(),
  carte_rose: yup.number(),
  droit_de_timbre_automobile: yup.number(),
  garanties: yup.array().required("valeur requise").min(1),
  date_avenant: yup.date().required("La date de l'avenant est obligatoire"),
  identite_vehicule: yup.object({
    idsources_energie: yup.number().required("valeur requise"),
    nombre_places: yup.number().min(3).max(100).required("valeur requise"),
    date_premiere_mise_en_circulation: yup.date(),
    valeur_a_neuf: yup.number().required("valeur requise"),
    valeur_venale: yup
      .number()
      .required("valeur requise")
      .test(
        "inferieur-valeur-neuf",
        "La valeur vénale doit être inférieure à la valeur à neuf",
        function (valeur_venale) {
          return valeur_venale < this.parent.valeur_a_neuf;
        }
      ),
  }),
});

function ExtensionDeGarantiesModal({
  callback = () => {},
  open = false,
  setOpen,
  souscription_uuid,
  onSuccessCallBack = () => {},
}) {
  const userData = useRecoilValue(authState);
  const [directivesTarifairesValues, setDirectivesTarifairesValues] = useState(
    []
  );
  const [companyDirectiveValues, setCompanyDirectiveValues] = useState([]);
  const [avariesFacultatives, setAvariesFacultatives] = useState([]);
  const [souscription, setSouscription] = useState(null);
  const [joursRestants, setJoursRestants] = useState(0);
  const [montantAPayer, setMontantAPayer] = useState(0);
  const [primeNette, setPrimeNette] = useState(0);
  const [coutTaxes, setCoutTaxes] = useState(0);

  const formik = useFormik({
    initialValues: {
      montant_commission: 0,
      prime_nette: 0,
      prime_nette_totale: 0,
      prime_nette_iac: 0,
      cout_police: 0,
      taxes: 0,
      montant_gestion_pool: 0,
      carte_rose: 0,
      droit_de_timbre_automobile: 0,
      garanties: [],
      date_avenant: "",
      identite_vehicule: {
        idsources_energie: 0,
        nombre_places: 0,
        date_premiere_mise_en_circulation: "",
        valeur_a_neuf: 0,
        valeur_venale: 0,
      },
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const data = collecteDataForExtensionGaranties(values);
      data.prime_nette = primeNette;
      data.cout_taxes = coutTaxes;
      data.cout_police = values.cout_police / 2;
      data.montant_a_payer = montantAPayer;
      avenantsService
        .createAvenantExtensionGaranties(souscription_uuid, data)
        .then((response) => {
          setSubmitting(false);
          onSuccessCallBack();
          toast("Extension de garantie effectuée avec succès!", {
            type: "success",
            title: "Succès",
          });
          setOpen(false);
        })
        .catch((error) => {
          toast(
            `${
              error.response.data ||
              "Une erreur s'est produite pendant l'opération"
            }`,
            {
              type: "error",
              title: "Erreur",
            }
          );
          setSubmitting(false);
        });
    },
  });

  const {
    values,
    setFieldValue,
    handleBlur,
    handleChange,
    handleReset,
    errors,
    handleSubmit,
    isSubmitting,
    setSubmitting,
  } = formik;

  const fetchSouscription = async () => {
    try {
      const response = await policesService.findOne(souscription_uuid);
      setSouscription(response.data);
      // Initialiser avec les garanties non souscrites
      const garantiesNonSouscrites = response.data.souscription_garanties
        .filter(
          (garantie) =>
            garantie.company_has_liste_garanties
              .pool_directives_tarifaires_has_listes_garanties.is_required !== 1
        )
        .map((item) => {
          return {
            libelle:
              item.company_has_liste_garanties
                ?.pool_directives_tarifaires_has_listes_garanties
                ?.liste_garanties.name,
            uuid: item.company_has_liste_garanties?.uuid,
            code: item.company_has_liste_garanties?.code,
            is_required:
              item.company_has_liste_garanties
                ?.pool_directives_tarifaires_has_listes_garanties
                ?.is_required === 1,
            risques: item.souscription_garanties_risques?.map((risque) => {
              return {
                libelle: risque.liste_garanties_risques.name,
                uuid: risque.liste_garanties_risques.uuid,
                checked:
                  item.souscription_garanties_risques
                    ?.pool_directives_tarifaires_has_listes_garanties
                    ?.is_required === 1,
                value: Number(risque.value),
              };
            }),
          };
        })
        .filter((garantie) => {
          const sommeRisques = garantie.risques
            .map((risque) => Number(risque.value))
            .reduce((acc, curr) => acc + curr, 0);
          return sommeRisques === 0 || garantie.risques.length > 1;
        });

      setFieldValue("garanties", garantiesNonSouscrites);
    } catch (error) {
      toast(
        `${
          error.response?.data ||
          "Une erreur s'est produite pendant l'opération"
        }`,
        {
          type: "error",
          title: "Erreur",
        }
      );
    }
  };

  const getDirectivesTarifairesValues = async () => {
    directivesTarifaireValuesService.getByActiveDirectivesTarifaires().then(
      (response) => {
        const directiveValue = (data, name) =>
          data.find((item) => item.name === name);
        setDirectivesTarifairesValues(response.data);
        formik.setFieldValue(
          "prime_nette_iac",
          directiveValue(response.data, "prime_nette_individuelle_conducteur")
            ?.value
        );
        formik.setFieldValue(
          "cout_police",
          directiveValue(response.data, "cout_police")?.value
        );
        formik.setFieldValue(
          "carte_rose",
          directiveValue(response.data, "carte_rose")?.value
        );

        formik.setFieldValue(
          "droit_de_timbre_automobile",
          directiveValue(response.data, "droit_de_timbre_automobile")?.value
        );
      },
      () => {}
    );
  };

  const fetchCompanyDirectiveValues = async () => {
    try {
      const response =
        await companyDirectiveValuesService.getByActiveDirectivesTarifaires(
          userData?.user?.intermediaire?.type_intermediaire === "COURTIER"
            ? souscription?.souscription_origine?.compagnies?.uuid || null
            : userData?.user?.intermediaire?.company?.uuid || null
        );
      setCompanyDirectiveValues(response?.data);
    } catch (error) {
      setCompanyDirectiveValues([]);
    }
  };

  const fetchAvariesFacultatives = async () => {
    try {
      const response =
        await avariesFacultativesService.getByActiveDirectiveTarifaires(
          userData?.user
        );
      setAvariesFacultatives(response?.data);
    } catch (error) {}
  };

  const initialValues = () => {
    if (!souscription) return;

    formik.setValues({
      date_avenant: "",
      garanties: values.garanties || [],
      identite_vehicule: {
        idsources_energie:
          souscription.souscription_identite_vehicule?.idsources_energie,
        nombre_places:
          souscription.souscription_identite_vehicule?.nombre_places,
        date_premiere_mise_en_circulation: dayjs(
          souscription.souscription_identite_vehicule
            ?.date_premiere_mise_en_circulation
        ).format("YYYY-MM-DD"),
        valeur_a_neuf:
          souscription.souscription_identite_vehicule?.valeur_a_neuf,
        valeur_venale:
          souscription.souscription_identite_vehicule?.valeur_venale,
      },
      conditions_particulieres: {
        pool_avaries_facultatives_uuid:
          souscription.conditions_particulieres.pool_avaries_facultatives?.uuid,
      },
      cout_police: directivesTarifairesValues.find(
        (item) => item.name === "cout_police"
      )?.value,
    });
  };

  useEffect(() => {
    if (souscription_uuid && open) {
      fetchSouscription();
      getDirectivesTarifairesValues();
      fetchAvariesFacultatives();
      fetchCompanyDirectiveValues();
    }
    return () => {};
  }, [
    open,
    souscription_uuid,
    userData?.user?.intermediaire?.type_intermediaire,
  ]);

  useEffect(() => {
    if (souscription && open) {
      initialValues();
    }
  }, [souscription, open]);

  useEffect(() => {
    const dateAvenant = dayjs(values.date_avenant);
    const dateApplication = dateAvenant.add(1, "day");
    const dateEcheance = dayjs(
      souscription?.conditions_particulieres?.date_echeance
    );
    const dateEffet = dayjs(souscription?.conditions_particulieres?.date_effet);
    const dureeContrat = dateEcheance.diff(dateEffet, "days");
    const joursRestants = dateEcheance.diff(dateApplication, "days");
    const totalGaranties = values.garanties.reduce((acc, garantie) => {
      return (
        acc +
        garantie.risques.reduce((acc, risque) => {
          return acc + parseFloat(risque.value);
        }, 0)
      );
    }, 0);

    const primeNette = Math.round(
      totalGaranties * (joursRestants / dureeContrat)
    );

    const taxeContratAssurance = directivesTarifairesValues?.find(
      (item) => item.name === "taxe_contrat_assurance"
    )?.value;

    const coutTaxes = Math.round(
      (taxeContratAssurance / 100) * (primeNette + values.cout_police / 2)
    );

    const total_a_payer = primeNette + values.cout_police / 2 + coutTaxes;

    setJoursRestants(joursRestants || 0);
    setPrimeNette(primeNette || 0);
    setCoutTaxes(coutTaxes || 0);
    setMontantAPayer(total_a_payer || 0);
  }, [values.date_avenant, values.garanties]);

  return (
    <Modal
      onClose={() => setOpen(false)}
      open={open}
      closeIcon
      size="fullscreen"
    >
      <FormikProvider value={formik}>
        <Modal.Header>Avenant d'extension de garanties</Modal.Header>
        <Modal.Content className="ui tiny form">
          <Grid divided>
            <Grid.Column width={4}>
              <Segment raised>
                <Form.Field required error={!!errors.date_avenant}>
                  <label>Date de l'avenant</label>
                  <Input
                    type="date"
                    value={values.date_avenant}
                    onChange={handleChange("date_avenant")}
                    onBlur={handleBlur("date_avenant")}
                  />
                  <small className="field-error">{errors.date_avenant}</small>
                </Form.Field>
                <Message>
                  <Message.Content>
                    <p>
                      La mise en vigueur de l'avenant est fixée au lendemain de
                      la date de l'avenant.
                    </p>
                  </Message.Content>
                </Message>
              </Segment>
              <Segment raised>
                <Header as="h5">Informations de la souscription</Header>
                <List>
                  <List.Item>
                    <List.Icon name="calendar alternate" />
                    <List.Content>
                      <List.Header>Date de prise d'effet</List.Header>
                      <List.Description>
                        {dayjs(
                          souscription?.conditions_particulieres?.date_effet
                        ).format("DD-MM-YYYY")}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Icon name="calendar alternate" />
                    <List.Content>
                      <List.Header>Date d'échéance</List.Header>
                      <List.Description>
                        {dayjs(
                          souscription?.conditions_particulieres?.date_effet
                        )
                          .add(
                            Number(
                              souscription?.conditions_particulieres
                                ?.pool_directives_tarifaires_has_insurances_durations
                                ?.insurances_durations?.months_number
                            ),
                            "months"
                          )
                          .add(-1, "day")
                          .format("DD-MM-YYYY")}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Icon name="calendar alternate" />
                    <List.Content>
                      <List.Header>Jours restants</List.Header>
                      <List.Description>
                        {dayjs(
                          souscription?.conditions_particulieres?.date_echeance
                        ).diff(
                          dayjs.max(
                            dayjs(),
                            dayjs(
                              souscription?.conditions_particulieres?.date_effet
                            )
                          ),
                          "days"
                        )}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                </List>
              </Segment>
            </Grid.Column>
            <Grid.Column width={9}>
              <Segment
                basic
                disabled={isDateBeforeDateEffet(
                  values.date_avenant,
                  dayjs(souscription?.conditions_particulieres?.date_effet)
                )}
              >
                <Card.Group itemsPerRow={3} stackable>
                  {formik.values.garanties?.map((garantie, index) => (
                    <GarantieItem
                      key={garantie.uuid}
                      garantie={garantie}
                      index={index}
                      formik={formik}
                      directivesTarifairesValues={directivesTarifairesValues}
                      companyDirectiveValues={companyDirectiveValues}
                      avariesFacultatives={avariesFacultatives}
                    />
                  ))}
                </Card.Group>
              </Segment>
            </Grid.Column>
            <Grid.Column width={3}>
              <Segment raised>
                <div>
                  <Header as="h5" className="!mb-0 !mt-1 !text-gray-500">
                    Jours de couverture
                  </Header>
                  <p className="text-right">{joursRestants} jours</p>
                  <Divider />
                </div>
                <div>
                  <Header as="h5" className="!mb-2 !mt-1 !text-gray-500">
                    Prime nette
                  </Header>
                  <p className="font-bold text-right">
                    {numeral(primeNette).format("")} FCFA
                  </p>
                </div>
                <Divider />
                <div>
                  <Header as="h5" className="!mb-2 !mt-1 !text-gray-500">
                    Taxes
                  </Header>
                  <p className="font-bold text-right">
                    {numeral(coutTaxes).format("")} FCFA
                  </p>
                </div>
                <Divider />
                <div>
                  <Header as="h5" className="!mb-2 !mt-1 !text-gray-500">
                    Cout de la police
                  </Header>
                  <p className="font-bold text-right">
                    {numeral(values.cout_police / 2).format("")} FCFA
                  </p>
                </div>
                <Divider />
                <div className="bg-slate-300 p-2 rounded-md !mt-3">
                  <Header as="h5" className="!mb-3">
                    Montant à payer
                  </Header>
                  <p className="font-bold text-right">
                    {numeral(montantAPayer).format("")} FCFA
                  </p>
                </div>
              </Segment>
            </Grid.Column>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => setOpen(false)}>
            Fermer
          </Button>
          <Button
            content="Enregistrer"
            labelPosition="right"
            icon="save"
            onClick={handleSubmit}
            positive
            loading={isSubmitting}
            disabled={
              isDateBeforeDateEffet(
                values.date_avenant,
                souscription?.conditions_particulieres?.date_effet
              ) ||
              isSubmitting ||
              primeNette === 0
            }
          />
        </Modal.Actions>
      </FormikProvider>
    </Modal>
  );
}

export default ExtensionDeGarantiesModal;
