import React, { useState } from "react";
import { Form, Input } from "semantic-ui-react";
import * as compagniesPartnersService from "../../../../services/compagniesPartnersService";

function CalculCommission({ formik }) {
  const { values, errors, handleBlur, handleChange } = formik;
  const [partners, setPartners] = useState([]);
  const fetchPartners = async () => {
    try {
      const response = await compagniesPartnersService.fetchAll();
      if (response.status === 200 && Array.isArray(response.data)) {
        setPartners(response.data);
      }
    } catch (error) {}
  };
  React.useEffect(() => {
    fetchPartners();
    return () => {};
  }, []);
  return (
    <React.Fragment>
      <Form.Field>
        <label>Apporteur d'affaire</label>
        <Form.Select
          placeholder="Selectionner un apporteur d'affaire"
          fluid
          search
          selection
          options={[
            {
              key: 0,
              value: 0,
              text: "Aucun",
            },
          ].concat(
            partners.map((partner) => ({
              key: partner.idcompagnies_intermediaires,
              value: partner.idcompagnies_intermediaires,
              text: `${partner?.intermediaires?.denomination}`,
            }))
          )}
          value={values.idcompagnies_intermediaires}
          onChange={(e, data) => {}}
        />
      </Form.Field>
      <Form.Group widths={"4"}>
        <Form.Field>
          <label>
            Taux de commisionnement <small>(en %)</small>
          </label>
          <Input type="number" readOnly value={18} />
          <small className="field-error"></small>
        </Form.Field>
        <Form.Field error={"montant_commission" in errors}>
          <label>Montant de la commission</label>
          <Input
            type="number"
            readOnly
            value={values.montant_commission}
            onChange={handleChange("montant_commission")}
            onBlur={handleBlur("montant_commission")}
          />
          <small className="field-error">{errors.montant_commission}</small>
        </Form.Field>
      </Form.Group>
    </React.Fragment>
  );
}

export default CalculCommission;
