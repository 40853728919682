import { Text, View } from "@react-pdf/renderer";
import numeral from "numeral";
import React from "react";
import convertToWord from "../../../utils/numberToWords";

function ConditionsParticulieresTarification({ souscription, styles }) {
  return (
    <>
      <View
        style={{
          marginTop: 10,
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          borderBottom: 1,
        }}
      >
        <View style={{ width: "70%", marginRight: "10%" }}>
          <View>
            <Text style={{fontSize: 10 }}>
              Par conséquent, le souscripteur s'engage à payer au comptant à la
              signature du présent contrat la somme de{" "}
              <Text style={{ fontWeight: "ultralight" }}>
                {convertToWord(numeral(souscription?.prime_ttc).format())}{" "}
                ( {numeral(souscription?.prime_ttc).format()})
              </Text>{" "}
              FCFA.
            </Text>
          </View>
          <Text style={{ fontWeight: "bold", marginTop: 10 }}>
          La prise d’effet du contrat est subordonnée au paiement de la prime par le souscripteur ( article 13 du code CIMA).
          La prime est payable au domicile de l’assureur ou de l’intermédiaire dans les conditions prévues à l’article 541 du code CIMA.
          </Text>
        </View>
        <View style={{ width: "30%" }}>
          <View style={[styles.sectionItem, { paddingRight: 10 }]}>
            <View>
              <Text style={{ fontWeight: "bold" }}>Prime Nette Totale</Text>
            </View>
            <View>
              <Text>{numeral(souscription?.prime_nette_totale).format()}</Text>
            </View>
          </View>
          <View style={[styles.sectionItem, { paddingRight: 10 }]}>
            <View>
              <Text style={{ fontWeight: "bold" }}>Frais gest. Pool</Text>
            </View>
            <View>
              <Text>
                {numeral(souscription?.montant_gestion_pool).format()}
              </Text>
            </View>
          </View>
          <View style={[styles.sectionItem, { paddingRight: 10 }]}>
            <View>
              <Text style={{ fontWeight: "bold" }}>Coût de police</Text>
            </View>
            <View>
              <Text>{numeral(souscription?.cout_police).format()}</Text>
            </View>
          </View>
          <View style={[styles.sectionItem, { paddingRight: 10 }]}>
            <View>
              <Text style={{ fontWeight: "bold" }}>Taxes</Text>
            </View>
            <View>
              <Text>{numeral(souscription?.taxes).format()}</Text>
            </View>
          </View>
          <View style={[styles.sectionItem, { paddingRight: 10 }]}>
            <View>
              <Text style={{ fontWeight: "bold" }}>Carte Rose</Text>
            </View>
            <View>
              <Text>{numeral(souscription?.carte_rose).format()}</Text>
            </View>
          </View>
          <View style={[styles.sectionItem, { paddingRight: 10 }]}>
            <View>
              <Text style={{ fontWeight: "bold" }}>Droit Timbre</Text>
            </View>
            <View>
              <Text>
                {numeral(souscription?.droit_de_timbre_automobile).format()}
              </Text>
            </View>
          </View>
          <View
            style={[
              styles.sectionItem,
              {
                padding: 5,
                borderTop: 1,
                paddingTop: 4,
                backgroundColor: "#dce1e6",
              },
            ]}
          >
            <View>
              <Text style={{ fontWeight: "bold" }}>Total à payer</Text>
            </View>
            <View>
              <Text style={{ fontWeight: "bold", fontSize: 10 }}>
                {numeral(souscription?.prime_ttc).format()}
              </Text>
            </View>
          </View>
        </View>
      </View>
    </>
  );
}

export default ConditionsParticulieresTarification;
