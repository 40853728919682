import React, { useState } from "react";
import { Button, Modal, Table, Label, Input } from "semantic-ui-react";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import * as avenantsService from "../../services/gestion_production/avenantsService";
import numeral from "numeral";

function GenerationPoliceModal({
  callback = () => {},
  open = false,
  setOpen,
  souscription,
  avenant_uuid,
  onSuccessPaymentCallBack = () => {},
  onCloseCallBack = () => {},
}) {
  const [loading, setLoading] = useState(false);
  const [numeroAttestation, setNumeroAttestation] = useState("");

  const generatePolice = () => {
    setLoading(true);
    avenantsService
      .generatePolice(avenant_uuid, {
        numero_attestation: numeroAttestation,
      })
      .then((res) => {
        setLoading(false);
        onSuccessPaymentCallBack(res);
        toast("La génération de la police a été effectuée avec succès", {
          type: "success",
          title: "Succès",
        });
        setOpen(false);
      })
      .catch((err) => {
        toast(
          "Erreur lors de la génération de la police/Numéro d'attestation non trouvé ou déjà utilisé",
          {
            type: "error",
            title: "Erreur",
          }
        );
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      closeIcon
    >
      <Modal.Header>Génération de la police</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Création</Table.HeaderCell>
                <Table.HeaderCell>Nom Assuré</Table.HeaderCell>
                <Table.HeaderCell>Montant Prime</Table.HeaderCell>
                <Table.HeaderCell>Montant à verser</Table.HeaderCell>
                <Table.HeaderCell>Statut</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  {dayjs(
                    souscription?.avenants?.find(
                      (avenant) => avenant.uuid === avenant_uuid
                    )?.created_at
                  ).format("DD-MM-YYYY")}
                </Table.Cell>
                <Table.Cell>
                  {souscription?.souscripteurs.groupe_souscripteur ===
                  "PARTICULIER"
                    ? `${souscription.souscripteurs.last_name}
                     ${souscription?.souscripteurs?.first_name}`
                    : souscription?.souscripteurs?.denomination}
                </Table.Cell>
                <Table.Cell>
                  {souscription?.avenants?.find(
                    (avenant) => avenant.uuid === avenant_uuid
                  )?.type_avenant === "AFFAIRE_NOUVELLE"
                    ? numeral(
                        souscription?.avenants?.length > 0
                          ? souscription?.prime_ttc
                          : 0
                      ).format()
                    : numeral(
                        souscription?.avenants?.find(
                          (avenant) => avenant.uuid === avenant_uuid
                        )?.bills?.amount || 0
                      ).format()}
                </Table.Cell>
                <Table.Cell>
                  {souscription?.avenants?.find(
                    (avenant) => avenant.uuid === avenant_uuid
                  )?.type_avenant === "AFFAIRE_NOUVELLE"
                    ? numeral(
                        souscription?.avenants?.length > 0
                          ? souscription?.prime_ttc
                          : 0
                      ).format()
                    : numeral(
                        souscription?.avenants?.find(
                          (avenant) => avenant.uuid === avenant_uuid
                        )?.bills?.amount || 0
                      ).format()}
                </Table.Cell>
                <Table.Cell>
                  <Label
                    size="tiny"
                    color={
                      souscription?.avenants?.find(
                        (avenant) => avenant.uuid === avenant_uuid
                      )?.bills?.payments || null
                        ? "green"
                        : "red"
                    }
                  >
                    {souscription?.avenants?.find(
                      (avenant) => avenant.uuid === avenant_uuid
                    )?.bills?.payments
                      ? "PAYE"
                      : "NON PAYE"}
                  </Label>
                  {souscription?.avenants?.find(
                    (avenant) => avenant.uuid === avenant_uuid
                  )?.bills?.payments && (
                    <div className="mt-2">
                      {dayjs(
                        souscription?.avenants?.find(
                          (avenant) => avenant.uuid === avenant_uuid
                        )?.bills?.payments?.created_at
                      ).format("DD-MM-YYYY HH:mm:ss")}
                    </div>
                  )}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
            {!souscription?.souscription_polices?.numero_police && (
              <Table.Footer>
                <Table.Row>
                  <Table.Cell colSpan={3} textAlign="center">
                    Entrer un numéro d'attestation valide
                  </Table.Cell>
                  <Table.Cell colSpan={2}>
                    <Input
                      fluid
                      placeholder="Numéro d'attestation"
                      value={numeroAttestation}
                      onChange={(e) => setNumeroAttestation(e.target.value)}
                    />
                  </Table.Cell>
                </Table.Row>
              </Table.Footer>
            )}
          </Table>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="black"
          onClick={() => {
            setOpen(false);
            onCloseCallBack();
          }}
        >
          Fermer
        </Button>
        {!souscription?.souscription_polices?.numero_police && (
          <Button
            disabled={loading}
            content="Générer la police"
            labelPosition="right"
            icon="payment"
            onClick={generatePolice}
            positive
            loading={loading}
          />
        )}
      </Modal.Actions>
    </Modal>
  );
}

export default GenerationPoliceModal;
