import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  BreadcrumbDivider,
  BreadcrumbSection,
  Button,
  Divider,
  Dropdown,
  Grid,
  GridRow,
  Header,
  Icon,
  Input,
  Label,
  List,
  Segment,
  Table,
} from "semantic-ui-react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Page from "../../components/Page";
import * as compagniesService from "../../services/comagniesService";
import CompanyUserFormModal from "../../components/modalForms/CompanyUserFormModal";
import { USERS_ROLE_ENUMS } from "../../constants";
import { useRecoilValue } from "recoil";
import { authState } from "../../atoms/authState";
import CompanyAgencyFormModal from "../../components/modalForms/CompanyAgencyFormModal";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as yup from "yup";

const validationSchema = yup.object().shape({
  logo: yup.mixed(),
  denomination: yup.string().required("La dénomination est requise"),
  siege_social: yup.string().required("Le siège social est requis"),
});

function CompanyDetails() {
  const [company, setCompany] = useState();
  const [openUserModal, setOpenUserModal] = useState(false);
  const [openAgencyModal, setOpenAgencyModal] = useState(false);
  const authValue = useRecoilValue(authState);
  const [imageError, setImageError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAgencyId, setSelectedAgencyId] = useState();
  const [logoPreview, setLogoPreview] = useState(null);
  const [logoFile, setLogoFile] = useState(null);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [isFileDialogOpen, setIsFileDialogOpen] = useState(false);

  const handleLogoChange = (event) => {
    try {
      if (!event?.target?.files?.length) {
        return;
      }

      const file = event.target.files[0];
      const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
      const maxSize = 5 * 1024 * 1024;

      if (!allowedTypes.includes(file.type)) {
        toast("Format de fichier non supporté. Utilisez JPEG, PNG ou GIF.", {
          type: "error",
          theme: "colored",
        });
        return;
      }

      if (file.size > maxSize) {
        toast("La taille du fichier ne doit pas dépasser 5 Mo.", {
          type: "error",
          theme: "colored",
        });
        return;
      }

      if (logoPreview && logoPreview.startsWith("blob:")) {
        URL.revokeObjectURL(logoPreview);
      }

      const previewUrl = URL.createObjectURL(file);
      setLogoFile(file);
      setLogoPreview(previewUrl);
      setFieldValue("logo", file);
      setIsFileDialogOpen(false);
    } catch (error) {
      toast("Une erreur s'est produite lors du traitement du fichier.", {
        type: "error",
        theme: "colored",
      });
    }
  };
  const { uuid } = useParams();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      logo: null,
      denomination: company?.denomination || "",
      siege_social: company?.siege_social || "",
      is_active: parseInt(company?.is_active),
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setSubmitting(true);
      const formData = new FormData();
      if (values.logo) {
        formData.append("logo", values.logo);
      }

      formData.append("denomination", values.denomination);
      formData.append("siege_social", values.siege_social);
      formData.append("is_active", values.is_active);

      compagniesService
        .update(uuid, formData)
        .then(
          (response) => {
            toast("Logo de la Compagnie mise à jour avec succès.", {
              type: "success",
              theme: "colored",
            });
            setShowSaveButton(false);
            handleReset();
            setLogoFile(null);
            setLogoPreview(null);
            fetchCompany();
          },
          (reason) => {
            toast(`${reason?.response?.data || reason?.message}`, {
              type: "error",
              theme: "colored",
            });
          }
        )
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const { isSubmitting, setSubmitting, handleReset, setFieldValue } = formik;

  const fetchCompany = async () => {
    try {
      const response = await (authValue?.user?.user_role ===
      USERS_ROLE_ENUMS.COMPANY_USER
        ? compagniesService.fetchOwner()
        : compagniesService.fetchOne(uuid || ""));

      if (response?.status === 200) {
        setCompany(response?.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    /*  if (params?.uuid) {
     
    } */ fetchCompany();
    return () => {};
  }, [uuid, authValue?.user]);

  const handleImageLoad = () => {
    setIsLoading(false);
    setImageError(false);
  };

  const getImageUrl = (path) => {
    if (!path) return null;

    if (path.startsWith("http://") || path.startsWith("https://")) {
      return path;
    }
    const cleanPath = path.replace(/^api\/?/, "");
    const baseUrl = process.env.REACT_APP_API_URL || "http://localhost:3000";

    return `${baseUrl}${cleanPath}`;
  };

  const handleImageError = (e) => {
    const imgUrl = getImageUrl(company.logo_path);
    fetch(imgUrl)
      .then((response) => {})
      .catch((error) => {});

    e.target.onerror = null;
    e.target.src = "/placeholder-logo.png";
  };

  useEffect(() => {
    if (company) {
      formik.setValues({
        logo: null,
        denomination: company.denomination || "",
        siege_social: company.siege_social || "",
        is_active: parseInt(company.is_active),
      });
    }
  }, [company]);

  return (
    <Page title="Compagnie détails">
      <GridRow className="CompagnyDetails">
        <Grid.Row className="!mb-5">
          <Grid.Column width={16}>
            <Breadcrumb>
              <BreadcrumbSection link>Pool</BreadcrumbSection>
              <BreadcrumbDivider icon="right chevron" />
              <BreadcrumbSection
                link
                as={Link}
                to="/pool/administration/compagnies"
              >
                Compagnies
              </BreadcrumbSection>
              <BreadcrumbDivider icon="right arrow" />
              <BreadcrumbSection
                link
                as={Link}
                to={`/pool/administration/compagnies/details/${uuid}`}
              >
                Détails de la compagnie
              </BreadcrumbSection>
            </Breadcrumb>
            <Divider />
          </Grid.Column>
        </Grid.Row>
        <Header>Détails de la compagnie</Header>
        <GridRow as={Segment}>
          <Label ribbon>{company?.denomination}</Label>
          <Grid stackable columns={4} style={{ marginTop: "5px" }}>
            <Grid.Column>
              <List>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">Dénomination</List.Header>
                    <List.Description>{company?.denomination}</List.Description>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">Contacts</List.Header>
                    <List.Description>
                      {company?.phone_1 || "-"}/{company?.phone_2 || "-"}
                    </List.Description>
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column>
              <List>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">Siège Social</List.Header>
                    <List.Description>{company?.siege_social}</List.Description>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">Email</List.Header>
                    <List.Description>
                      {company?.email || "Non défini"}
                    </List.Description>
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column>
              <List>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">RCCM/</List.Header>
                    <List.Description>
                      {company?.rccm || "Non défini"}
                    </List.Description>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name="tag" />
                  <List.Content>
                    <List.Header as="a">Web/</List.Header>
                    <List.Description>
                      {company?.web || "Non défini"}
                    </List.Description>
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column>
              <div className="relative w-[200px] h-[100px] border-2 border-dashed border-gray-300 rounded p-5 cursor-pointer hover:border-gray-400 transition-colors">
                {logoPreview ? (
                  <div className="relative w-full h-full">
                    {isLoading && (
                      <div className="absolute inset-0 flex items-center justify-center">
                        <Icon name="spinner" className="animate-spin" />
                      </div>
                    )}
                    <div className="relative w-full h-full group">
                      <img
                        src={logoPreview}
                        alt="Company Logo"
                        className={`w-full h-full object-contain ${
                          isLoading ? "hidden" : "block"
                        }`}
                      />
                      <div className="absolute bottom-0 left-0 right-0 bg-black/40 py-2 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center">
                        <Button
                          onClick={() => formik.handleSubmit()}
                          disabled={isSubmitting}
                          className="text-white text-sm"
                        >
                          <Icon name="save" className="mr-2" />
                          Enregistrer
                        </Button>
                      </div>
                    </div>
                  </div>
                ) : company?.logo_path && !imageError ? (
                  <div className="relative w-full h-full">
                    {isLoading && (
                      <div className="absolute inset-0 flex items-center justify-center">
                        <Icon name="spinner" className="animate-spin" />
                      </div>
                    )}
                    <div className="relative w-full h-full group">
                      <img
                        src={getImageUrl(company.logo_path)}
                        alt="Company Logo"
                        className={`w-full h-full object-contain ${
                          isLoading ? "hidden" : "block"
                        }`}
                        onError={handleImageError}
                        onLoad={handleImageLoad}
                        onClick={() => setShowSaveButton(!showSaveButton)}
                      />
                      <div className="absolute bottom-0 left-0 right-0 bg-black/50 py-2 opacity-0 group-hover:opacity-100 transition-opacity">
                        <label
                          htmlFor="logo-upload"
                          className="block text-center text-white text-sm font-medium cursor-pointer"
                        >
                          Mettre à jour
                          <input
                            id="logo-upload"
                            type="file"
                            accept="image/jpeg,image/png,image/gif"
                            className="hidden"
                            onChange={handleLogoChange}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                ) : (
                  <label
                    htmlFor="logo-upload"
                    className="flex flex-col items-center justify-center h-full"
                  >
                    <input
                      id="logo-upload"
                      type="file"
                      accept="image/jpeg,image/png,image/gif"
                      className="hidden"
                      onChange={handleLogoChange}
                    />
                    <Icon name="cloud upload" size="big" />
                    <p className="mt-2 text-sm text-gray-600">
                      Cliquez ici pour Ajouter votre logo
                    </p>
                  </label>
                )}
              </div>
            </Grid.Column>
          </Grid>
        </GridRow>
        <GridRow as={Segment}>
          <Grid stackable>
            <Grid.Column
              width={8}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Header>Liste des utilisateurs</Header>
            </Grid.Column>
            <Grid.Column
              width={8}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Input placeholder="Rechercher..." className="search">
                <input name="search" />
              </Input>
              <Button
                style={{ marginLeft: "10px" }}
                circular
                icon={<Icon name="add" />}
                onClick={() => setOpenUserModal(true)}
              />
            </Grid.Column>
          </Grid>

          <Table striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell>Nom</Table.HeaderCell>
                <Table.HeaderCell>Prénom</Table.HeaderCell>
                <Table.HeaderCell>Email</Table.HeaderCell>
                <Table.HeaderCell>Téléphones</Table.HeaderCell>
                <Table.HeaderCell>Admin</Table.HeaderCell>
                <Table.HeaderCell>Actif</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {company?.users_compagnies?.map((user_company, index) => {
                return (
                  <Table.Row key={`${user_company.idusers_compagnies}`}>
                    <Table.Cell>{index + 1}</Table.Cell>
                    <Table.Cell>{user_company?.users?.last_name}</Table.Cell>
                    <Table.Cell>{user_company?.users?.first_name}</Table.Cell>
                    <Table.Cell>{user_company?.users?.email}</Table.Cell>
                    <Table.Cell>{user_company?.users?.phone}</Table.Cell>
                    <Table.Cell>{`${
                      user_company?.is_admin === 1 ? "OUI" : "NON"
                    }`}</Table.Cell>
                    <Table.Cell>{`${
                      user_company?.users?.active === 1 ? "OUI" : "NON"
                    }`}</Table.Cell>
                    <Table.Cell textAlign="right">
                      <Dropdown
                        pointing="right"
                        icon={<Icon name="ellipsis vertical" />}
                      >
                        <Dropdown.Menu>
                          <Dropdown.Item
                            icon={<Icon name="eye" />}
                            content="Détails"
                          />
                          <Dropdown.Item
                            icon={<Icon name="edit" />}
                            content="Editer"
                          />
                          <Dropdown.Item
                            icon={<Icon name="eye" />}
                            content="Supprimer"
                          />
                        </Dropdown.Menu>
                      </Dropdown>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </GridRow>
        <GridRow as={Segment} style={{ marginTop: "30px" }}>
          <Grid stackable>
            <Grid.Column
              width={8}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Header>Liste des Agences</Header>
            </Grid.Column>
            <Grid.Column
              width={8}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Input placeholder="Rechercher..." className="search">
                <input name="search" />
              </Input>
              {![
                USERS_ROLE_ENUMS.POOL_USER,
                USERS_ROLE_ENUMS.ROOT_ADMIN,
              ].includes(authValue?.user?.user_role) && (
                <Button
                  style={{ marginLeft: "10px" }}
                  circular
                  icon={<Icon name="add" />}
                  onClick={() => setOpenAgencyModal(true)}
                />
              )}
            </Grid.Column>
          </Grid>

          <Table striped celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell collapsing>#</Table.HeaderCell>
                <Table.HeaderCell>Dénomination</Table.HeaderCell>
                <Table.HeaderCell>Code</Table.HeaderCell>
                <Table.HeaderCell>Téléphones</Table.HeaderCell>
                <Table.HeaderCell>Adresse</Table.HeaderCell>
                <Table.HeaderCell>Actif</Table.HeaderCell>
                <Table.HeaderCell textAlign="right" collapsing>
                  Actions
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {company?.compagnies_agencies?.map((agency, index) => {
                return (
                  <Table.Row key={`${agency.idcompagnies_agencies}`}>
                    <Table.Cell>{index + 1}</Table.Cell>
                    <Table.Cell>{agency.denomination}</Table.Cell>
                    <Table.Cell>{agency.code}</Table.Cell>
                    <Table.Cell>
                      {agency.phone_1}/{agency.phone_2}
                    </Table.Cell>
                    <Table.Cell>{agency.address}</Table.Cell>
                    <Table.Cell>
                      <Label
                        size="mini"
                        color={agency.is_active === 1 ? "green" : "red"}
                      >
                        {agency.is_active === 1 ? "OUI" : "NON"}
                      </Label>
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                      <Dropdown
                        pointing="right"
                        icon={<Icon name="ellipsis vertical" />}
                      >
                        <Dropdown.Menu>
                          <Dropdown.Item
                            icon={<Icon name="eye" />}
                            content="Détails"
                            onClick={() =>
                              navigate(
                                `/company/agences/details/${agency.idcompagnies_agencies}`
                              )
                            }
                          />
                          <Dropdown.Item
                            icon={<Icon name="edit" />}
                            content="Editer"
                            onClick={() => {
                              setOpenAgencyModal(true);
                              setSelectedAgencyId(agency.idcompagnies_agencies);
                            }}
                          />
                          <Dropdown.Item
                            icon={<Icon name="trash" />}
                            content="Supprimer"
                          />
                        </Dropdown.Menu>
                      </Dropdown>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </GridRow>
      </GridRow>
      <CompanyUserFormModal
        open={openUserModal}
        setOpen={setOpenUserModal}
        company={company}
        callback={fetchCompany}
      />
      <CompanyAgencyFormModal
        open={openAgencyModal}
        setOpen={setOpenAgencyModal}
        callback={fetchCompany}
        selectedAgencyId={selectedAgencyId}
        handleClose={() => setSelectedAgencyId(null)}
      />
    </Page>
  );
}

export default CompanyDetails;
