import {
  PDFViewer,
  Page as PageView,
  Text,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import "dayjs/locale/fr";
dayjs.locale("fr");

function ChangementNom({ souscription, avenant_uuid }) {
  const [avenant, setAvenant] = useState(null);

  useEffect(() => {
    if (avenant_uuid && souscription) {
      setAvenant(
        souscription?.avenants?.find((avenant) => avenant.uuid === avenant_uuid)
      );
    }
  }, [avenant_uuid, souscription]);

  return (
    <PDFViewer style={{ width: "100%", height: "100vh" }}>
      <Document>
        <PageView size="A4" style={styles.page}>
          <View style={styles.avenantHeaderSection}>
            <Text style={styles.avenantHeaderTitle}>
              Avenant de changement de nom
            </Text>
          </View>

          <View style={[styles.souscripteurSection, { fontWeight: "bold" }]}>
            <View style={[styles.souscripteurItem]}>
              <View style={styles.souscripteurItemValue}>
                <Text>Assuré: </Text>
                <Text>
                  {souscription?.souscripteur_assure?.assures?.nom_assure}
                </Text>
              </View>
              <View style={styles.souscripteurItemValue}>
                <Text>Adresse :</Text>
                <Text>
                  {souscription?.souscripteur_assure?.assures?.adresse_assure}
                </Text>
              </View>
              <View style={styles.souscripteurItemValue}>
                <Text>Tél: </Text>
                <Text>
                  {souscription?.souscripteur_assure?.assures?.phone_assure}
                </Text>
              </View>
            </View>
            <View style={styles.souscripteurItem}>
              <View style={styles.souscripteurItemValue}>
                <Text>Effet: </Text>
                <Text>
                  {dayjs(avenant?.date_avenant)
                    .hour(0)
                    .minute(0)
                    .second(0)
                    .add(1, "day")
                    .format("DD/MM/YYYY")}
                </Text>
              </View>
              <View style={styles.souscripteurItemValue}>
                <Text>Expiration: </Text>
                <Text>
                  {dayjs(souscription?.conditions_particulieres?.date_effet)
                    .add(
                      Number(
                        souscription?.conditions_particulieres
                          ?.pool_directives_tarifaires_has_insurances_durations
                          ?.insurances_durations?.months_number
                      ),
                      "months"
                    )
                    .add(-1, "day")
                    .format("DD/MM/YYYY")}
                </Text>
              </View>
            </View>
          </View>

          <View
            style={{
              marginBottom: 50,
              padding: 10,
              textAlign: "center",
              fontSize: 14,
            }}
          >
            <Text style={{ textAlign: "center" }}>
              (Concerne la police Automobile n°{" "}
              <Text style={{ fontWeight: "bold" }}>
                {souscription?.souscription_polices?.numero_police}
              </Text>
              )
            </Text>
          </View>
          <View
            style={{
              marginBottom: 50,
              padding: 10,
              textAlign: "center",
              fontSize: 14,
            }}
          >
            <Text style={{ textAlign: "justify" }}>
              <Text>
                {"      "}A la demande de l'assuré et d'un commun accord entre
                les parties, il est convenu qu'à compter du{" "}
              </Text>
              <Text style={{ fontWeight: "extrabold" }}>
                {dayjs(avenant?.date_avenant)
                  .hour(0)
                  .minute(0)
                  .second(0)
                  .add(1, "day")
                  .format("DD MMMM YYYY")}
              </Text>
              , le véhicule de marque{" "}
              <Text style={{ fontWeight: "extrabold" }}>
                {souscription?.souscription_identite_vehicule?.marque_vehicule}
              </Text>
              , Genre,{" "}
              <Text style={{ fontWeight: "extrabold" }}>
                {souscription?.souscription_identite_vehicule?.genre_vehicule}
              </Text>
              , immatriculé{" "}
              <Text style={{ fontWeight: "extrabold" }}>
                {
                  souscription?.souscription_identite_vehicule
                    ?.immatriculation_vehicule
                }
              </Text>
              , assuré précédemment au nom de{" "}
              <Text style={{ fontWeight: "extrabold" }}>
                {souscription?.souscripteur_assure?.assures?.nom_assure}
              </Text>
              , est désormais assuré sous celui-ci mentionné :{" "}
              <Text style={{ fontWeight: "extrabold" }}>
                {avenant?.meta_data?.souscription_assures?.nom_assure}
              </Text>
            </Text>
          </View>
          <View
            style={{
              marginBottom: 50,
              padding: 10,
              fontSize: 14,
              fontWeight: "extrabold",
            }}
          >
            <Text style={{ fontWeight: "extrabold" }}>
              Il n'est rien changé d'autre aux conditions tant générales que
              paticulières de la police.
            </Text>
          </View>
          <View
            style={{
              textAlign: "right",
              fontSize: 14,
            }}
          >
            <Text
              style={{ fontWeight: "extrabold", textDecoration: "underline" }}
            >
              Fait à Brazzaville, le{" "}
              <Text style={{ fontWeight: "extrabold" }}>
                {dayjs(avenant?.date_effet).format("DD MMMM YYYY")}
              </Text>
            </Text>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              fontSize: 14,
              marginTop: 50,
            }}
          >
            <Text>(Pour l'assuré)</Text>
            <Text>(Pour la compagnie)</Text>
          </View>
        </PageView>
      </Document>
    </PDFViewer>
  );
}

export default ChangementNom;

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFF",
    padding: 50,
    fontSize: 10,
    lineHeight: 1.5,
  },
  avenantHeaderSection: {
    backgroundColor: "#CCC",
    padding: 10,
  },
  avenantHeaderTitle: {
    fontSize: 14,
    fontWeight: "bold",
    textAlign: "center",
    textTransform: "uppercase",
  },
  souscripteurSection: {
    marginTop: 50,
    marginBottom: 50,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    textTransform: "uppercase",
    gap: 10,
    padding: 10,
  },
  souscripteurItem: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    textTransform: "uppercase",
    width: "50%",
  },
  souscripteurItemValue: {
    fontWeight: "bold",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    textTransform: "uppercase",
    fontSize: 14,
  },
});
