import {
  PDFViewer,
  Page as PageView,
  Text,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import numeral from "numeral";
import { getOneAvenant } from "../../../services/gestion_production/avenantsService";

function ExtensionGarntiesPreview({ souscription, avenant_uuid }) {
  const [avenant, setAvenant] = useState(null);

  useEffect(() => {
    try {
      if (avenant_uuid) {
        getOneAvenant(avenant_uuid)
          .then((res) => setAvenant(res.data))
          .catch();
      }
    } catch (error) {}
  }, [avenant_uuid]);

  return (
    <PDFViewer style={{ width: "100%", height: "100vh" }}>
      <Document>
        <PageView size="A4" style={styles.page}>
          <>
            <View
              style={{
                marginTop: 50,
                backgroundColor: "#dce1e6",
                padding: 5,
              }}
            >
              <Text
                style={{
                  fontWeight: "bold",
                  fontSize: 14,
                  textAlign: "center",
                  textTransform: "uppercase",
                }}
              >
                Avenant d'extension de garanties
              </Text>
            </View>

            <View style={{ marginTop: 20, flexDirection: "column", gap: 2 }}>
              <View style={{ flexDirection: "row", fontWeight: "bold" }}>
                <Text>POLICE :</Text>
                <Text style={{ paddingLeft: 19 }}>
                  {souscription?.souscription_polices?.numero_police || "N/A"}
                </Text>
              </View>
              <View style={{ flexDirection: "row", fontWeight: "bold" }}>
                <Text>ASSURE :</Text>
                <Text style={{ paddingLeft: 15 }}>
                  {souscription?.souscripteur_assure?.assures.nom_assure ||
                    "N/A"}
                </Text>
              </View>
              <View style={{ flexDirection: "row", fontWeight: "bold" }}>
                <Text>EFFET :</Text>
                <Text style={{ paddingLeft: 25, fontWeight: "bold" }}>
                  {dayjs(avenant?.date_avenant)
                    .hour(0)
                    .minute(0)
                    .second(0)
                    .add(1, "day")
                    .format("DD-MM-YYYY")}
                </Text>
              </View>
              <View style={{ flexDirection: "row", fontWeight: "bold" }}>
                <Text>EXPIRATION :</Text>
                <Text>
                  {dayjs(souscription?.conditions_particulieres?.date_effet)
                    .add(
                      Number(
                        souscription?.conditions_particulieres
                          ?.pool_directives_tarifaires_has_insurances_durations
                          ?.insurances_durations?.months_number
                      ),
                      "months"
                    )
                    .add(-1, "day")
                    .format("DD-MM-YYYY")}
                </Text>
              </View>
            </View>

            <View style={{ marginTop: 25 }}>
              <Text>
                A la demande de l'assuré(e), et d'un commun accord entre les
                parties, il a été convenu qu'à compter du{" "}
                <Text>
                  {dayjs(avenant?.date_avenant)
                    .hour(0)
                    .minute(0)
                    .second(0)
                    .add(1, "day")
                    .format("DD-MM-YYYY")}
                </Text>{" "}
                {avenant?.meta_data?.garanties?.length > 1
                  ? " les garanties "
                  : " la garantie "}{" "}
                {avenant?.meta_data?.garanties?.map((garantie) => (
                  <Text key={garantie.uuid}>
                    {
                      garantie?.pool_directives_tarifaires_has_listes_garanties
                        ?.liste_garanties?.name
                    }
                    {" , "}
                  </Text>
                ))}
                {avenant?.meta_data?.garanties?.length > 1
                  ? " sont incluses "
                  : " est incluse "}
                aux autres garanties du véhicule ci-après faisant partie du
                présent contrat.
              </Text>
            </View>

            <View style={{ marginTop: 10 }}>
              <Text style={{ fontWeight: "bold", fontSize: 10 }}>
                {souscription?.souscription_identite_vehicule?.marque_vehicule}{" "}
                :{" "}
                {
                  souscription?.souscription_identite_vehicule
                    ?.immatriculation_vehicule
                }
              </Text>
              <Text>
                Valeur vénale du véhicule :{" "}
                {numeral(
                  souscription?.souscription_identite_vehicule?.valeur_venale
                ).format()}{" "}
                FCFA
              </Text>
            </View>

            <View style={{ marginTop: 10 }}>
              <Text
                style={{
                  fontWeight: "bold",
                  fontSize: 10,
                  textDecoration: "underline",
                }}
              >
                Garanties incluses :
              </Text>
              <View style={{ marginTop: 10 }}>
                <Text>
                  {souscription?.souscription_garanties
                    ?.filter((garantie) => {
                      const sommeRisques =
                        garantie.souscription_garanties_risques?.reduce(
                          (acc, risque) => acc + (risque.value || 0),
                          0
                        );
                      return (
                        sommeRisques > 0 ||
                        garantie.company_has_liste_garanties
                          ?.pool_directives_tarifaires_has_listes_garanties
                          .is_required === 1
                      );
                    })
                    ?.map((garantie) => (
                      <Text key={garantie.uuid}>
                        {
                          garantie.company_has_liste_garanties
                            ?.pool_directives_tarifaires_has_listes_garanties
                            ?.liste_garanties?.name
                        }
                        {" , "}
                      </Text>
                    ))}
                </Text>
              </View>
            </View>

            <View style={{ marginTop: 10 }}>
              <Text>
                De ce fait, la nouvelle Prime Nette Annuelle est désormais la
                suite :
              </Text>
              <View style={{ marginTop: 10 }}>
                <View>
                  <View style={{ flexDirection: "row", paddingTop: 5 }}>
                    <Text style={{ width: "50%" }}>Ancienne Prime Nette</Text>
                    <Text style={{ width: "50%" }}>
                      {numeral(souscription?.prime_nette).format()} FCFA
                    </Text>
                  </View>

                  <View style={{ flexDirection: "row", paddingTop: 5 }}>
                    <Text style={{ width: "50%" }}>Nouvelle Prime Nette</Text>
                    <Text style={{ width: "50%" }}>
                      {numeral(
                        avenant?.meta_data?.souscription?.prime_nette
                      ).format()}{" "}
                      FCFA
                    </Text>
                  </View>

                  <View style={{ flexDirection: "row", paddingTop: 5 }}>
                    <Text style={{ width: "50%" }}>Augmentation Nette</Text>
                    <Text style={{ width: "50%" }}>
                      {numeral(
                        avenant?.meta_data?.souscription?.prime_nette -
                          souscription?.prime_nette
                      ).format()}{" "}
                      FCFA
                    </Text>
                  </View>
                </View>
              </View>
            </View>

            <View style={{ marginTop: 20, marginBottom: 10 }}>
              <Text>
                En conséquence, l'assuré paiera comptant à la signature du
                présent avenant, la somme de{" "}
                {numeral(
                  avenant?.meta_data?.souscription?.prime_nette +
                    avenant?.meta_data?.souscription?.cout_police +
                    avenant?.meta_data?.souscription?.cout_taxes
                ).format()}{" "}
                FCFA ainsi décompté :
              </Text>

              <View style={{ marginTop: 10 }}>
                <View
                  style={{
                    flexDirection: "row",
                    borderBottom: 1,
                    padding: 5,
                    marginBottom: 5,
                  }}
                >
                  <Text style={{ width: "70%", fontWeight: "bold" }}>
                    Désignation
                  </Text>
                  <Text
                    style={{
                      width: "30%",
                      fontWeight: "bold",
                      textAlign: "right",
                    }}
                  >
                    Montant
                  </Text>
                </View>

                <View style={{ flexDirection: "row" }}>
                  <Text style={{ width: "70%" }}>Prime nette</Text>
                  <Text style={{ width: "30%", textAlign: "right" }}>
                    {numeral(
                      avenant?.meta_data?.souscription?.prime_nette
                    ).format()}{" "}
                    FCFA
                  </Text>
                </View>
                <View style={{ flexDirection: "row" }}>
                  <Text style={{ width: "70%" }}>Cout de police</Text>
                  <Text style={{ width: "30%", textAlign: "right" }}>
                    {numeral(
                      avenant?.meta_data?.souscription?.cout_police
                    ).format()}{" "}
                    FCFA
                  </Text>
                </View>
                <View style={{ flexDirection: "row" }}>
                  <Text style={{ width: "70%" }}>Taxes</Text>
                  <Text style={{ width: "30%", textAlign: "right" }}>
                    {numeral(
                      avenant?.meta_data?.souscription?.cout_taxes
                    ).format()}{" "}
                    FCFA
                  </Text>
                </View>
                <View
                  style={{ flexDirection: "row", fontSize: 12, paddingTop: 4 }}
                >
                  <Text style={{ width: "70%", fontWeight: "bold" }}>
                    Prime Totale
                  </Text>
                  <Text
                    style={{
                      width: "30%",
                      fontWeight: "bold",
                      textAlign: "right",
                    }}
                  >
                    {numeral(
                      avenant?.meta_data?.souscription?.prime_nette +
                        avenant?.meta_data?.souscription?.cout_police +
                        avenant?.meta_data?.souscription?.cout_taxes
                    ).format()}{" "}
                    FCFA
                  </Text>
                </View>
              </View>
            </View>

            <View
              style={{
                marginTop: 10,
                textAlign: "center",
                width: "100%",
                flexDirection: "row",
                gap: 5,
              }}
            >
              <Text style={{ fontWeight: "bold", textTransform: "center" }}>
                Pour la période allant du{" "}
                {dayjs(avenant?.date_avenant)
                  .hour(0)
                  .minute(0)
                  .second(0)
                  .add(1, "day")
                  .format("DD-MM-YYYY")}{" "}
                au{" "}
                {dayjs(souscription?.conditions_particulieres?.date_effet)
                  .add(
                    Number(
                      souscription?.conditions_particulieres
                        ?.pool_directives_tarifaires_has_insurances_durations
                        ?.insurances_durations?.months_number
                    ),
                    "months"
                  )
                  .add(-1, "day")
                  .format("DD-MM-YYYY")}
              </Text>
              <Text>
                soit pour{" "}
                {dayjs(
                  dayjs(souscription?.conditions_particulieres?.date_effet)
                    .add(
                      Number(
                        souscription?.conditions_particulieres
                          ?.pool_directives_tarifaires_has_insurances_durations
                          ?.insurances_durations?.months_number
                      ),
                      "months"
                    )
                    .add(-1, "day")
                ).diff(dayjs(avenant?.date_avenant), "days") + 1}{" "}
                jours
              </Text>
              <Text>
                coefficient :{" "}
                {(
                  (dayjs(
                    dayjs(souscription?.conditions_particulieres?.date_effet)
                      .add(
                        Number(
                          souscription?.conditions_particulieres
                            ?.pool_directives_tarifaires_has_insurances_durations
                            ?.insurances_durations?.months_number
                        ),
                        "months"
                      )
                      .add(-1, "day")
                  ).diff(dayjs(avenant?.date_avenant), "days") +
                    1) /
                  (Number(
                    souscription?.conditions_particulieres
                      ?.pool_directives_tarifaires_has_insurances_durations
                      ?.insurances_durations?.months_number
                  ) *
                    30)
                ).toFixed(2)}
              </Text>
            </View>
            <View style={{ marginTop: 30, marginBottom: 30 }}>
              <Text style={{ fontStyle: "italic", textAlign: "right" }}>
                Fait à _________________, le ______________
              </Text>
            </View>
            <View
              style={{
                marginTop: 20,
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <View style={{ width: "45%" }}>
                <Text
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                    textTransform: "uppercase",
                    textDecoration: "underline",
                  }}
                >
                  Le Souscripteur
                </Text>
              </View>
              <View style={{ width: "45%" }}>
                <Text
                  style={{
                    marginBottom: 10,
                    fontWeight: "bold",
                    textTransform: "uppercase",
                    textDecoration: "underline",
                    textAlign: "right",
                  }}
                >
                  Pour la Compagnie
                </Text>
              </View>
            </View>
          </>
        </PageView>
      </Document>
    </PDFViewer>
  );
}

export default ExtensionGarntiesPreview;

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFF",
    padding: 30,
    fontSize: 10,
    lineHeight: 1.5,
  },
  section: {
    padding: 20,
  },
  sectionItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: 3,
    fontSize: 8,
  },
});
