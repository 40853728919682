import { api } from "../axios/api";

export const findAll = async (params) => {
  return api.get(`gestion-production/souscriptions`, { params });
};

export const fetchOne = async (id) => {
  return api.get(`gestion-production/souscriptions/${id}`);
};
export const create = async (data, company_uuid) => {
  return api.post(`gestion-production/souscriptions`, data, {
    params: {
      company_uuid,
    },
  });
};

export const deleteSouscription = async (uuid) => {
  return api.delete(`gestion-production/souscriptions/${uuid}`);
};
